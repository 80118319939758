import React from "react";
import {
  Container,
  Autocomplete,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  FormLabel,
  Box,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FRONTEND_API } from "./urls";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import dayjs from "dayjs";
import { ButtonContainer } from "./styles/style";
import IconButton from "@mui/material/IconButton";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { currencies } from "./constants/constants";
import AssingExpert from "./icons/edit.png";
import DeleteIcon from "@mui/icons-material/Delete";

import AddClientDialog from "./AddClientDialog";

import MessageComponent from "./MessageComponent";
import AddUserDialog from "./AddUserDialog";
import { ToastContainer, toast } from "react-toastify";
import CommonModal from "./common/CommonModal";

function AddTaskNew() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [userClientType, setUserClientType] = useState("");
  const [userType, setUserType] = useState("");
  const [isOpenUserDialog, setIsOpenUserDialog] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const buttonRef = useRef(null);
  const today = dayjs();

  const [currencyValue, setCurrencyValue] = React.useState("");
  const [expertCurrency, setExpertCurrency] = React.useState("");
  const [userClient, setUserClient] = React.useState("");
  const [client, setclient] = useState([]);
  const [Vendor_budget, setVendor_budget] = useState("");
  const [orderId, setOrderId] = useState("");
  const [newSubject, setNewSubject] = useState("");
  const [End_date, setEnd_date] = useState(dayjs().startOf("day"));
  const [Description, setDescription] = useState("");
  const [Word_count, setWord_count] = useState("");
  const [Expert_price, setExpert_price] = useState("");

  const [orderIdValid, setOrderIdValid] = useState(null);
  const [clientValid, setClientValid] = useState(null);
  const [subjectValid, setSubjectValid] = useState(null);
  const [vendorBudgetValid, setVendorBudgetValid] = useState(null);
  const [wordCountValid, setWordCountValid] = useState(null);
  const [expertPriceValid, setExpertPriceValid] = useState(null);
  const [openExpertsDialog, setOpenExpertDialog] = useState(false);
  const [expert, setexpert] = useState([]);
  const [tasks, setTasks] = useState([{ invoiceDate: today, wordCount: "" }]);
  const [Comments, setComments] = useState("");
  const [openInvoiceDatePicker, setOpenInvoiceDatePicker] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [expertValid, setExpertValid] = useState(null);
  const validateExpert = (value) => value != "";
  const [Qc_Expert_name, setQc_Expert_name] = useState("");
  const [Status, setStatus] = useState("");
  const roles = localStorage.getItem("roles");
  const [selectedOrderId, setSelectedOrderId] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const invoiceButtonRef = useRef(null);
  const resetFormFields = () => {
    setVendor_budget("");
    setNewSubject("");
    setOrderId("");
    setDescription("");
    setWord_count("");
    setExpert_price("");
  };

  const validateSubject = (value) => value != "";
  const validateClient = (value) => value != "";

  const validateOrderId = (value) => value != "";

  const validateVendorBudget = (value) => /^\d+$/.test(value); // Example validation for a numeric value
  const vaildateWordCount = (value) => /^\d+$/.test(value);
  const validateExpertPrice = (value) => {
    console.log(parseInt(Vendor_budget, 10));
    if (value < parseInt(Vendor_budget, 10)) {
      console.log(value);
      return true;
    } else {
      return false;
    }
  };

  const handleCurrencyChange = (event) => {
    setCurrencyValue(event.target.value);
  };
  const expertCurrencyChange = (event) => {
    setExpertCurrency(event.target.value);
  };

  const handleOrderIdChange = (event) => {
    setOrderId(event.target.value);
    setOrderIdValid(validateOrderId(event.target.value));
  };

  const handleNewSubject = (event) => {
    const { name, value } = event.target;
    setNewSubject(value);
    setSubjectValid(validateSubject(value));
    console.log(event.target);
  };

  const handleChangeClient = (event, newValue) => {
    //const newValue = event.target.value;
    /*   setUserClient(newValue);
        setClientValid(validateClient(newValue))
        console.log(event.target.value);
        if (newValue.name === 'New Client') {
            handleCreateClient();
            setUserClient(null);
        }
 */
    if (!newValue) return;
    if (newValue.name === "New Client") {
      handleCreateClient();
      setUserClient(null);
    } else {
      console.log("id inside handle", newValue.id);
      setUserClient(newValue.id);
      setClientValid(validateClient(newValue.id));
    }
  };
  const [openClientDialog, setOpenClientDialog] = useState(false);

  const handleCreateClient = () => {
    setOpenClientDialog(true);
  };

  const handleCloseClientDialog = () => {
    setOpenClientDialog(false);
  };

  const handleVendorBudgetChange = (event) => {
    const { name, value } = event.target;
    setVendor_budget(value);
    setVendorBudgetValid(validateVendorBudget(value));
  };

  const handleWordCountChange = (event) => {
    const newValue = event.target.value;
    setWord_count(newValue);
    setWordCountValid(vaildateWordCount(newValue));
  };

  const handleExpertPriceChange = (event) => {
    const newValue = event.target.value;
    setExpert_price(newValue);
    setExpertPriceValid(validateExpertPrice(newValue));
  };

  const handleEndDateChange = (date) => {
    //const { name, value } = event.target;
    console.log(date);
    setEnd_date(date);
    setOpenDatePicker(false);

    console.log(End_date);
  };

  const handleRadioChange = (event) => {
    //setSelectedRadio(event.target.value);
    console.log(event.target.value);
    setUserClientType(event.target.value);
    fetchInitial(event.target.value);
  };

  const handleUserTypeChange = (event) => {
    //setSelectedRadio(event.target.value);
    console.log(event.target.value);
    setUserType(event.target.value);
    //fetchInitial(event.target.value);
    fetchExperts(event.target.value);
  };
  const fetchExperts = (type) => {
    var formdata = new FormData();
    formdata.append("user_type", type);

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    fetch(FRONTEND_API + "getExpertToAssign", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        // do something with data
        console.log(data);
        setexpert(data);
      })
      .catch((rejected) => {
        console.log(rejected);
      });
  };

  const fetchInitial = (type) => {
    console.log("type initial", type);
    if (type === "student") {
      fetch(FRONTEND_API + "getstudentclientdata", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // do something with data
          console.log(data);
          setclient(data);
        })
        .catch((rejected) => {
          console.log(rejected);
        });
    } else {
      fetch(FRONTEND_API + "getvendoreclientdata", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // do something with data
          console.log(data);
          setclient(data);
        })
        .catch((rejected) => {
          console.log(rejected);
        });
    }
  };

  const resetValidationFields = () => {
    setSubjectValid(null);
    setVendorBudgetValid(null);
    setWordCountValid(null);
    setExpertPriceValid(null);
  };

  const [dialogValidationOpen, setDialogValidationOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [severity, setSeverity] = useState(null);

  const handleCloseAlertDialog = () => {
    setDialogValidationOpen(false);
  };

  const handleUserDialogeClose = () => {
    setIsOpenUserDialog(false);
  };
  const uploadData = (event) => {
    event.preventDefault();
    console.log(userClient, "Client");

    let respStatus = 0;

    if (!orderIdValid) {
      setDialogValidationOpen(true);
      setErrorMessage("Order ID is not valid");
      setSeverity(false);
      return;
    } else if (!subjectValid) {
      setDialogValidationOpen(true);
      setErrorMessage("Subject is not valid");
      setSeverity(false);
      return;
    } else if (userClient == "") {
      setDialogValidationOpen(true);
      setErrorMessage("Please Select the client");
      setSeverity(false);
      return;
    }

    // Proceed with form submission

    console.log("Form submitted");
    var formdata = new FormData();
    formdata.append("order_id", orderId);
    formdata.append("Task_Subject", newSubject);
    // formdata.append("currency", currencyValue);
    //formdata.append("expert_currency", expertCurrency);
    //formdata.append("Vendor_budget", Vendor_budget);
    formdata.append("Comments", Comments);
    formdata.append("client_id", userClient);
    formdata.append("Start_date", today);
    formdata.append("End_date", End_date.format("YYYY-MM-DD"));
    formdata.append("Description", Description);
    formdata.append("Word_count", Word_count);
    if (currentStatus === "assigned") {
      formdata.append("Status", "assigned");
      formdata.append("data", JSON.stringify({ task: tasks }));
      formdata.append("expert_id", Qc_Expert_name);
    } else {
      formdata.append("Status", "new order");
    }
    if (userType == "freelancer") {
      formdata.append("Expert_price", Expert_price);
    } else {
      formdata.append("Expert_price", 0);
    }

    console.log(formdata);

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    fetch(FRONTEND_API + "addtask", requestOptions)
      .then((response) => {
        if (response.status == 200) {
          respStatus = 200;
          return response.json();
        } else if (response.status == 400) {
          respStatus = response.status;
          return response.json();
        }
      })
      .then((result) => {
        if (respStatus == 200) {
          setDialogValidationOpen(true);
          setErrorMessage(result.message);
          setSeverity(true);
          resetFormFields();
          setCurrentStatus("");
          setUserType("");
          setTasks([{ invoiceDate: today, wordCount: "" }]);
          setComments("");
          setQc_Expert_name("");
          setOpenExpertDialog(false);
          resetValidationFields();
        } else if (respStatus == 400) {
          console.log(result);
          setDialogValidationOpen(true);
          setErrorMessage(result.message);
          setSeverity(false);
        }

        //navigate('/Assingntask');
      })
      .catch((error) => console.log(error));
  };

  const handleButtonClick = () => {
    if (openDatePicker) {
      setOpenDatePicker(false);
    } else {
      setOpenDatePicker(true);
    }
  };

  const handleOpenUserDialoge = () => {
    setIsOpenUserDialog(true);
  };

  const handleAssignExpertDialog = () => {
    setOpenExpertDialog(true);
    setCurrentStatus("assigned");
  };

  const handleCloseExpertDialog = () => {
    setOpenExpertDialog(false);
  };

  const updateStatusData = (condition) => {
    var formdata = new FormData();

    formdata.append("updateCondition", condition);
    if (condition == "status") {
      //Only status is updated
      formdata.append("status", Status);
    } else {
      // if (currentStatus == "new order") {
      formdata.append("roles", roles);
      formdata.append("status", "assigned");
      formdata.append("data", JSON.stringify({ task: tasks }));
      formdata.append("expert_id", Qc_Expert_name);
      formdata.append("comments", Comments);
      // } else if (currentStatus == "assigned") {
      //   formdata.append("roles", roles);
      //   formdata.append("data", JSON.stringify({ task: tasks }));
      //   formdata.append("qc_expert_id", Qc_Expert);
      //   formdata.append("expert_id", Qc_Expert_name);
      //   formdata.append("comments", Comments);
      // }
      console.log("type", userType);
      // if (roles == 'lead') {
      //     formdata.append('roles', 'lead');
      // }
      // if (currentStatus == 'new order') {
      //     formdata.append('status', 'assigned');
      //     formdata.append('expert_date', invoiceDate.format('YYYY-MM-DD'));
      // }
      // formdata.append('expert_id', Qc_Expert_name);
      // formdata.append('expert_date', invoiceDate.format('YYYY-MM-DD'));
    }

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    fetch(FRONTEND_API + "updateStatusWithId/".concat(orderId), requestOptions)
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        }
      })
      .then((data) => {
        //do something with data
        resetFormFields();
        resetValidationFields();
        if (condition == "expert") {
          setOpenExpertDialog(false);
        }
      })
      .catch((rejected) => {
        console.log(rejected);
      });
  };

  const assignExpertHandler = (event) => {
    uploadData(event);
    // }
    setUserType("");
    setTasks([{ invoiceDate: today, wordCount: "" }]);
    setComments("");

    setQc_Expert_name("");
  };

  const handleChangeQc = (event) => {
    const value = event.target.value;
    setQc_Expert_name(value);
    setExpertValid(validateExpert(value));
    console.log(event.target.value);
  };

  const handleChangecomment = (event) => {
    const value = event.target.value;
    setComments(value);
    setExpertValid(validateExpert(value));
    console.log(event.target.value);
  };

  const handleInvoiceButtonClick = (index) => {
    console.log("index from the function", index);
    setSelectedIndex(index);
    setOpenInvoiceDatePicker(!openInvoiceDatePicker);
  };

  const handleInvoiceDateChange = (date) => {
    // console.log("handle invoice date change", date, "id is", id);
    // console.log("id from parent", id);
    console.log("tasks", tasks);
    const updatedTasks = tasks.map((task, i) =>
      i === selectedIndex ? { ...task, invoiceDate: date } : task
    );
    setTasks(updatedTasks);
    setOpenInvoiceDatePicker(false);
  };
  const onChangeWordCount = (event, index) => {
    console.log("indside wordcount index", index);
    const { value } = event.target;
    // Update the word count for the specific task at the given index
    const updatedTasks = tasks.map((task, i) =>
      i === index ? { ...task, wordCount: value } : task
    );
    // Update tasks state with the modified tasks array
    setTasks(updatedTasks);
  };
  const removeTask = (index) => {
    const updatedTasks = tasks.filter((task, i) => i !== index);
    setTasks(updatedTasks);
  };

  const addNewTask = () => {
    setTasks([...tasks, { invoiceDate: today, wordCount: "" }]);
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <h1>Add Task </h1>
      <form autoComplete="off" onSubmit={uploadData}>
        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField
            id="outlined-basic"
            value={orderId}
            onChange={handleOrderIdChange}
            variant="outlined"
            error={orderIdValid == false}
            helperText={orderIdValid == false && "Cannot be left blank"}
            label="Order ID"
          />
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField
            id="outlined-basic"
            value={newSubject}
            onChange={handleNewSubject}
            variant="outlined"
            error={subjectValid == false}
            helperText={subjectValid == false && "Cannot be left blank"}
            label="Subject"
          />
        </FormControl>
        <FormLabel
          sx={{ m: 1, marginTop: 3 }}
          id="demo-row-radio-buttons-group-label"
        >
          Task Deadline
        </FormLabel>
        <Grid sx={{ display: "flex" }}>
          <ButtonContainer
            sx={{ m: 1, height: "60px" }}
            role="button"
            onClick={handleButtonClick}
            size="small"
            ref={buttonRef}
          >
            <span style={{ marginLeft: "10px" }}>
              {End_date.format("YYYY-MM-DD")}
            </span>
            <IconButton
              sx={{ display: "flex", alignItems: "end", alignContent: "end" }}
            >
              <CalendarTodayIcon />
            </IconButton>
          </ButtonContainer>

          {openDatePicker && (
            <div
              style={{
                zIndex: 99,
                top:
                  buttonRef.current.offsetTop +
                  buttonRef.current.offsetHeight +
                  -70 +
                  "px",
                left: buttonRef.current.offsetLeft + 250 + "px",
                backgroundColor: "#fff",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  value={End_date}
                  onChange={(newValue) => handleEndDateChange(newValue)}
                  renderInput={() => null} // Hide the input inside the calendar
                  PopperProps={{
                    style: { zIndex: 99, backgroundColor: "#fff" }, // Adjust z-index as needed
                  }}
                />
              </LocalizationProvider>
            </div>
          )}
        </Grid>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <FormControl fullWidth sx={{ m: 1, marginTop: 3 }}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Client
              </FormLabel>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={userClientType}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="student"
                  control={<Radio />}
                  label="Student"
                />
                <FormControlLabel
                  value="vendor"
                  control={<Radio />}
                  label="Vendor"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {userClientType != "" && (
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <FormControl
                fullWidth
                sx={{
                  marginTop: 3,
                  m: 1,
                }}
              >
                {/* <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={userClient}
                                    label="Experts"
                                    error={clientValid == false}
                                    helperText={clientValid == false && 'Invalid Client'}
                                    variant='outlined'
                                    onChange={handleChangeClient}
                                >
                                    {client.map((data) => (
                                        <MenuItem value={data.id}>{data.name}</MenuItem>

                                    ))}
                                    <MenuItem value="new-client">+ Add New Client</MenuItem>
                                </Select> */}
                <Grid
                  display={"flex"}
                  sx={{ width: "380px" }}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  gap={2}
                >
                  <Autocomplete
                    options={client}
                    fullWidth
                    getOptionLabel={(option) => option.name}
                    onChange={handleChangeClient}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ marginTop: 1, maxWidth: 250 }}
                        size="large"
                        variant="outlined"
                        label="Client"
                      />
                    )}
                  />
                  <Button
                    variant="outlined"
                    sx={{
                      height: "35px",
                      width: "163px",
                      padding: "20px",
                      marginTop: "5px",
                    }}
                    onClick={handleCreateClient}
                  >
                    Add Client
                  </Button>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        )}

        <FormControl fullWidth sx={{ m: 1, marginTop: 3 }}>
          <TextField
            id="outlined-basic"
            value={Description}
            onInput={(e) => {
              setDescription(e.target.value);
            }}
            label="Description"
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth sx={{ m: 1, marginTop: 3 }}>
          <TextField
            id="outlined-basic"
            value={Word_count}
            error={wordCountValid == false}
            onChange={handleWordCountChange}
            helperText={wordCountValid == false && "Invalid Word Count"}
            label="Word Count"
            variant="outlined"
          />
        </FormControl>

        <FormControl fullWidth sx={{ m: 1, marginTop: 3 }}>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Assign Task
          </FormLabel>
          <Grid
            display={"flex"}
            width={"100%"}
            justifyContent={"start"}
            alignItems={"center"}
            gap={4}
          >
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={userType}
              onChange={handleUserTypeChange}
            >
              <FormControlLabel
                value="permanent"
                control={<Radio />}
                label="Permanent"
              />
              <FormControlLabel
                value="freelancer"
                control={<Radio />}
                label="Freelancer"
              />
            </RadioGroup>
            <Button variant="outlined" onClick={handleAssignExpertDialog}>
              Assign Expert
            </Button>
            <Button variant="outlined" onClick={handleOpenUserDialoge}>
              Add user
            </Button>
          </Grid>
        </FormControl>
        <CommonModal
          type={openExpertsDialog ? "edit" : ""}
          title={openClientDialog ? "Assign Expert" : ""}
          handleClose={openExpertsDialog ? handleCloseExpertDialog : () => {}}
          isOpen={openExpertsDialog ? openExpertsDialog : false}
          handleConfirm={openExpertsDialog ? assignExpertHandler : () => {}}
          modalIcon={openExpertsDialog ? AssingExpert : ""}
          content={
            openExpertsDialog ? (
              <>
                <FormControl fullWidth>
                  <Box>
                    <FormControl fullWidth sx={{ marginTop: 1 }}>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Assign Task
                      </FormLabel>

                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={userType}
                        onChange={handleUserTypeChange}
                      >
                        <FormControlLabel
                          value="lead"
                          control={<Radio />}
                          label="Team Lead"
                        />

                        <FormControlLabel
                          value="permanent"
                          control={<Radio />}
                          label="Permanent"
                        />
                        <FormControlLabel
                          value="freelancer"
                          control={<Radio />}
                          label="Freelancer"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ width: "250px", marginTop: "10px" }}>
                      <InputLabel>Expert</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={Qc_Expert_name}
                        label="Experts"
                        variant="outlined"
                        error={expertValid == false}
                        helperText={expertValid == false && "Select Expert"}
                        onChange={handleChangeQc}
                      >
                        {expert.map((data) => (
                          <MenuItem value={data.id}>
                            {data.firstname} {data.lastname}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Box sx={{ height: "10px" }} />{" "}
                    {/* This will add space between the fields */}
                    <FormControl sx={{ width: "250px", marginTop: "10px" }}>
                      {/* <InputLabel>Comments</InputLabel> */}

                      <TextField
                        required
                        id="demo1-simple-select"
                        value={Comments}
                        label="Comment"
                        onChange={handleChangecomment}
                      />
                    </FormControl>
                    <FormControl sx={{ marginTop: "10px" }} fullWidth>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Task Deadline
                      </FormLabel>
                      <div>
                        {tasks.map((task, index) => (
                          <div key={index}>
                            <ButtonContainer
                              role="button"
                              onClick={() => handleInvoiceButtonClick(index)}
                              size="small"
                              ref={invoiceButtonRef}
                            >
                              {console.log(
                                "date from JSX",
                                task.invoiceDate
                                  ? dayjs(task?.invoiceDate).format(
                                      "YYYY-MM-DD"
                                    )
                                  : ""
                              )}
                              {task.invoiceDate
                                ? dayjs(task?.invoiceDate).format("YYYY-MM-DD")
                                : ""}
                              <IconButton
                                sx={{
                                  display: "flex",
                                  alignItems: "end",
                                  alignContent: "end",
                                }}
                              >
                                <CalendarTodayIcon />
                              </IconButton>
                            </ButtonContainer>

                            {openInvoiceDatePicker && (
                              <div
                                style={{
                                  position: "absolute",
                                  zIndex: 99,
                                  top:
                                    invoiceButtonRef.current.offsetTop +
                                    invoiceButtonRef.current.offsetHeight +
                                    -170 +
                                    "px",
                                  left:
                                    invoiceButtonRef.current.offsetLeft +
                                    250 +
                                    "px",
                                  backgroundColor: "#fff",
                                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                                  transform: "scale(0.7)", // Adjust the scale to make the datepicker smaller
                                  transformOrigin: "top left", // Ensure the scaling happens from the top-left corner
                                }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  {/* {console.log(task.invoiceDate)} */}
                                  <DateCalendar
                                    value={
                                      task.invoiceDate
                                        ? dayjs(task.invoiceDate)
                                        : null
                                    }
                                    onChange={(newValue) => {
                                      console.log(
                                        "value from calendar",
                                        newValue
                                      );
                                      console.log("index from inner", index);
                                      handleInvoiceDateChange(newValue);
                                    }}
                                    renderInput={() => null}
                                    PopperProps={{
                                      style: {
                                        zIndex: 99,
                                        backgroundColor: "#fff",
                                      },
                                    }}
                                    minDate={
                                      task?.invoiceDate
                                        ? task?.invoiceDate
                                        : today
                                    }
                                    sx={{
                                      "& .MuiCalendarPicker-root": {
                                        transform: "scale(0.8)", // Adjust the scale to make it smaller
                                        transformOrigin: "top left", // Ensure the scaling happens from the top-left corner
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                              </div>
                            )}

                            <FormControl sx={{ marginTop: 2, marginBottom: 2 }}>
                              <TextField
                                required
                                id={`wordCount-${index}`}
                                label="Word Count"
                                name={`wordCount-${index}`}
                                value={task.wordCount}
                                onChange={(event) =>
                                  onChangeWordCount(event, index)
                                }
                              />
                            </FormControl>

                            <IconButton
                              sx={{ marginTop: 3 }}
                              onClick={() => removeTask(index)}
                              aria-label="delete"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        ))}

                        <Button variant="outlined" onClick={addNewTask}>
                          Add +
                        </Button>
                      </div>
                    </FormControl>
                  </Box>
                </FormControl>
              </>
            ) : (
              ""
            )
          }
        />
        {/* {userType == "freelancer" && (
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={4}>
              <FormControl fullWidth sx={{ m: 1, marginTop: 3 }}>
                <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={expertCurrency}
                  label="currency"
                  onChange={expertCurrencyChange}
                >
                  {currencies.map((data) => (
                    <MenuItem value={data.value}>{data.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth sx={{ m: 1, marginTop: 3 }}>
                <TextField
                  id="outlined-basic"
                  value={Expert_price}
                  label="Expert Price"
                  variant="outlined"
                  error={expertPriceValid == false}
                  onChange={handleExpertPriceChange}
                  helperText={expertPriceValid == false && "Invalid Price"}
                />
              </FormControl>
            </Grid>
          </Grid>
        )} */}

        <Button variant="outlined" type="submit" sx={{ marginTop: 3 }}>
          Submit
        </Button>
      </form>

      <AddClientDialog
        open={openClientDialog}
        handleClose={handleCloseClientDialog}
        fetchClientData={(type) => fetchInitial(type)}
      />
      <AddUserDialog
        handleClose={handleUserDialogeClose}
        open={isOpenUserDialog}
      />
      {dialogValidationOpen && (
        <MessageComponent
          message={errorMessage}
          severity={severity}
          close={handleCloseAlertDialog}
        />
      )}
    </Container>
  );
}

export default AddTaskNew;
