import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useAuth from "../hooks/useAuth";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { FRONTEND_API } from "./urls";
import loginPic2 from "./img/loginPicNew2.png";
import loginPic1 from "./img/loginPicNew1.png";
import googleIcon from "./img/search.png";
import appleIcon from "./img/apple-logo.png";
import faceBookIcon from "./img/facebook.png";
import json2mq from "json2mq";

import { Alert, Container, useMediaQuery } from "@mui/material";
import { useState } from "react";

// TODO remove, this demo shouldn't need to reset the theme.

export default function NewLogin() {
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const location = useLocation();

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState("");
  const [alertContent, setAlertContent] = useState("");

  const from = location.state?.from?.pathname || "/app_select";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const validateEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  const validatePassword = (value) => value.length >= 6;

  const [emailValid, setEmailValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setEmailValid(validateEmail(newEmail));
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setPasswordValid(validatePassword(newPassword));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });

    var formdata = new FormData();
    formdata.append("email", data.get("email"));
    formdata.append("password", data.get("password"));

    var requestOptions = {
      method: "POST",
      body: formdata,
    };

    fetch(FRONTEND_API + "login", requestOptions) 
.then((response) => response.json())

      .then((result) => {
        if (result.code != "400") {
          const roles_arr = [];
          localStorage.setItem("token", result.access_token);
          var roles = result.type;
          localStorage.setItem("roles", roles);
          localStorage.setItem("email", data.get("email"));
          var user_role = roles.split(" ");
          localStorage.setItem("userId", result.userId);

          console.log(result);
          console.log(user_role + "in login");
          setAuth({ roles });
          navigate(from, { replace: true });
          setAlert(false);
          setAlertContent(" ");
        } else {
          setStatus("400");
          setAlertContent(result.message);
          setAlert(true);
        }
      })
      .catch((error) => {
        setAlertContent(error);
        setAlert(true);
        console.log("error", error);
      });
  };

  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const iphoneSE = useMediaQuery(
    json2mq({
      maxWidth: 376,
    })
  );
  const bigScreen = useMediaQuery(
    json2mq({
      minWidth: 1366,
      minHeight: 768,
    })
  );

  const ipadPro = useMediaQuery(
    json2mq({
      maxWidth: 1024,
    })
  );

  const galaxyFold = useMediaQuery(
    json2mq({
      minWidth: 0,
      maxWidth: 283,
      minHeight: 0,
      maxHeight: 653,
    })
  );

  return (
    <Grid display="flex" minHeight="100vh" xs={12} width="100%">
      <Box
        sx={{
          backgroundColor: "rgb(73, 112, 193)",
          width: galaxyFold ? "100%" : "50%",
          height: galaxyFold ? "105vh" : "100vh",
        }}
      >
        <Grid display="flex" flexDirection="column" gap="80px">
          <Grid item xs={12} textAlign="start">
            <Typography
              margin="10px"
              padding="10px"
              fontWeight="semi-bold"
              color="#C6553B"
            ></Typography>
          </Grid>
          {!isMediumScreen && !ipadPro && (
            <Grid position="relative" item xs={12} marginLeft="190px">
              <img
                src={loginPic2}
                alt="sali image"
                style={{ width: "269px", height: "256px" }}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Paper
        sx={{
          position: "absolute",
          maxWidth: galaxyFold ? "270px" : !isSmallScreen ? "500px" : "326px",
          minHeight: galaxyFold ? "600px" : "500px",
          borderRadius: "40px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "36px",
          top: iphoneSE
            ? "4rem"
            : isSmallScreen
            ? "8rem"
            : isMediumScreen
            ? "15rem"
            : ipadPro
            ? "13rem"
            : bigScreen
            ? "123px"
            : "3rem",
          left: galaxyFold
            ? "4px"
            : iphoneSE
            ? "25px"
            : isSmallScreen
            ? "40px"
            : isMediumScreen
            ? "10rem"
            : ipadPro
            ? "16rem"
            : "30rem",
          padding: iphoneSE ? "35px" : "29px",
          zIndex: "10",
        }}
      >
        <Grid
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            xs={12}
            display="flex"
            justifyContent="start"
            alignItems="start"
            width="100%"
          >
            <Typography sx={{ fontSize: "20px", fontWeight: "regular" }}>
              Welcome To Tutorshive
            </Typography>
            <Grid
              display="flex"
              flexDirection="column"
              marginLeft="55px"
              alignItems="start"
              justifyContent="center"
            >
              <Typography fontSize="12px" color="#8D8D8D">
                No account?
              </Typography>
              <Typography fontSize="12px" color="#B87514">
                SignIn
              </Typography>
            </Grid>
          </Grid>
          <Grid width="100%">
            <Typography sx={{ fontSize: "53px", fontWeight: "medium" }}>
              SignIn
            </Typography>
          </Grid>
        </Grid>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            mt: 1,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <TextField
            margin="normal"
            required
            id="email"
            label="Email Address"
            name="email"
            autoFocus
            value={email}
            onChange={handleEmailChange}
            sx={{ color: emailValid ? "initial" : "red" }}
          />
          <TextField
            margin="normal"
            required
            name="password"
            label="Password"
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            sx={{ borderColor: passwordValid ? "initial" : "red" }}
          />

          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              bgcolor: "rgb(73, 112, 193)",
              color: "white",
              "&:hover": { bgcolor: "rgb(73, 112, 193)", color: "white" },
            }}
          >
            Sign In
          </Button>
          {alert && status == "400" ? (
            <Alert severity="error">{alertContent}</Alert>
          ) : (
            <></>
          )}
        </Box>
      </Paper>
      <Box
        sx={{
          width: galaxyFold ? "0%" : "50%",
          bgcolor: isMediumScreen || isSmallScreen ? "rgb(73, 112, 193)" : "",
        }}
      >
        {!isMediumScreen && !ipadPro && (
          <Grid xs={6} display="flex" alignItems="start">
            <Grid marginTop="144px" marginLeft="170px">
              <img
                src={loginPic1}
                alt="sali image"
                style={{ width: "436px", height: "400px" }}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Grid>
  );
}
