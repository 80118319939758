import {
  Box,
  TableHead,
  Snackbar,
  Alert,
  TextField,
  Typography,
} from "@mui/material";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableSortLabel,
} from "./styles/TableStyles";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { method, orderBy } from "lodash";
import TablePagination from "@mui/material/TablePagination";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { FRONTEND_API } from "./urls";

const TeamLeadOperationTable = ({
  tableType,
  tableHeaders,
  tableValues,
  getTableData,
  alertInfo,
  handleCloseAlert,
  setAlertInfo,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderDeadlineSort, setOrderDeadlineSort] = useState("asc");
  const [selectedOrderId, setSelectedOrderId] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  console.log("tableValue from team lead", tableValues);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const token = localStorage.getItem("token");
  console.log("token is here", token);
  const handleDates = (expertDates) => {
    if (!expertDates || expertDates.length === 0) {
      return null;
    }

    if (!Array.isArray(expertDates)) {
      expertDates = [expertDates];
    }

    const formattedDates = expertDates.map((dateString) => {
      const dateObject = new Date(dateString);
      const day = String(dateObject.getDate()).padStart(2, "0");
      const month = String(dateObject.getMonth() + 1).padStart(2, "0");
      const year = dateObject.getFullYear();
      return `${day}/${month}/${year}`;
    });

    return formattedDates.join(", ");
  };

  useEffect(() => {
    getTableData();
  }, [tableType]);

  // const getTableData = async () => {
  //   try {
  //     const response = await axios.post(
  //       tableType === "UrgentDeadline"
  //         ? `${FRONTEND_API}list_urgdeadline/`
  //         : tableType === "HighPriority"
  //         ? `${FRONTEND_API}list_high_priority_deadline/`
  //         : tableType === "meetingSchedule"
  //         ? `${FRONTEND_API}list_meetingdeadline/`
  //         : "",
  //       {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     console.log("response after fetch data", response);
  //     setTableValues(
  //       response.data.deadlines.filter((data) => data.is_status !== null)
  //     );
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setAlertInfo({
  //       open: true,
  //       message: error.response?.data?.message || "Unauthorized access",
  //       severity: "error",
  //     });
  //   }
  // };
  let sortedData = [];

  const handleRequestSort = (newValue) => {
    const newOrder = orderDeadlineSort === "asc" ? "desc" : "asc";
    console.log("new order is here", newOrder);
    setOrderDeadlineSort(newOrder);
  };

  const parseDate = (dateString) => {
    return new Date(dateString);
  };

  sortedData = orderBy(
    tableValues,
    [
      (row) =>
        parseDate(
          tableType === "UrgentDeadline"
            ? row.urgent_deadline
            : tableType === "HighPriority"
            ? row.high_priority_deadline
            : tableType === "meetingSchedule"
            ? row.meeting_sche_deadline
            : row.urgent_deadline
        ),
    ],
    [orderDeadlineSort]
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const serachedOrders = sortedData.filter(
    (order) =>
      order.orders_id?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.urgent_deadline
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      order.urgent_deadline_comment?.includes(searchQuery.toLowerCase()) ||
      order.high_priority_comment
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      order.high_priority_deadline
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      order.meeting_sche_deadline
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      order.meeting_sche_comment
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      order.meeting_sche_time?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleCloseAlert = () => {
  //   setAlertInfo({ open: false, message: "", severity: "success" });
  // };

  const handleIsTaskDone = debounce(async (event, orders) => {
    try {
      const response = await axios.post(
        `${FRONTEND_API}${
          tableType === "HighPriority"
            ? "high_priority_deadline_done"
            : tableType === "meetingSchedule"
            ? "meetingdeadline_done"
            : "urgent_deadline_done"
        }/${orders.orders_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;

      if (response.status < 299) {
        setAlertInfo({
          open: true,
          message: data.message ? data.message : "Order updated successfully",
          severity: "success",
        });
        getTableData();
      } else if (response.status >= 400 && response.status < 500) {
        setAlertInfo({
          open: true,
          message: data.message ? data.message : "Provide data properly",
          severity: "error",
        });
      } else {
        setAlertInfo({
          open: true,
          message: "Internal server error",
          severity: "error",
        });
      }
    } catch (error) {
      setAlertInfo({
        open: true,
        message: error.message ? error.message : "An error occurred",
        severity: "error",
      });
    }
  }, 1000);

  function convertToTime(dateString) {
    const date = new Date(dateString);

    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const seconds = date.getUTCSeconds().toString().padStart(2, "0");

    const amOrPm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;
    const formattedHours = hours.toString().padStart(2, "0");

    return `${formattedHours}:${minutes}:${seconds} ${amOrPm}`;
  }

  return (
    <div>
      <Typography
        sx={{
          fontSize: "2rem",
          fontWeight: "bold",
          color: "#333",
          padding: "10px 0",
          textAlign: "center",
        }}
      >
        {tableType}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          marginBottom: 5,
          marginTop: 2,
          marginRight: 2,
        }}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 5,
          marginTop: 2,
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            marginRight: 2,
          }}
          aria-label="customized table"
        >
          <TablePagination
            className="table-page"
            component="div"
            count={tableValues.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Orders per page:"
            labelDisplayedRows={({ from, to, count }) =>
              `Showing ${from} to ${to} Order of ${count} Order(s)`
            }
          />
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                {tableHeaders.map((item) => (
                  <StyledTableCell
                    onClick={() => {
                      handleRequestSort(orderDeadlineSort);
                    }}
                  >
                    {" "}
                    {item.toLowerCase().includes("deadline") ? (
                      <>
                        {orderDeadlineSort === "asc" ? (
                          <ArrowUpwardIcon />
                        ) : orderDeadlineSort === "desc" ? (
                          <ArrowDownwardIcon />
                        ) : (
                          <ArrowUpwardIcon />
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {item}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {serachedOrders.length > 0 ? (
                <>
                  {serachedOrders
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .map((orderData) => (
                      <StyledTableRow
                        key={orderData.id}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        {/* First Column: Order ID */}
                        <StyledTableCell component="th" scope="row">
                          {orderData.orders_id}
                        </StyledTableCell>

                        {/* Second Column: Deadline (Based on Table Type) */}
                        <StyledTableCell>
                          {handleDates(
                            tableType === "UrgentDeadline"
                              ? orderData.urgent_deadline
                              : tableType === "HighPriority"
                              ? orderData.high_priority_deadline
                              : tableType === "meetingSchedule"
                              ? orderData.meeting_sche_deadline
                              : orderData.urgent_deadline
                          )}
                        </StyledTableCell>

                        {/* Render the rest of the columns dynamically */}
                        {Object.keys(orderData)
                          .filter(
                            (key) =>
                              ![
                                "orders_id",
                                "expert_id",
                                "urgent_deadline",
                                "high_priority_deadline",
                                "meeting_sche_deadline",
                                "id",
                                "is_status",
                              ].includes(key)
                          ) // Exclude the unwanted keys and those already handled
                          .map((key, i) => (
                            <StyledTableCell key={i}>
                              {key.includes("comment")
                                ? orderData[key]
                                : key.includes("time")
                                ? convertToTime(orderData[key])
                                : orderData[key]}
                            </StyledTableCell>
                          ))}

                        {/* Checkbox Column */}
                        <StyledTableCell>
                          <Checkbox
                            {...label}
                            onChange={(event) =>
                              handleIsTaskDone(event, orderData)
                            }
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </>
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={6}>
                    No data available
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Snackbar
          open={alertInfo.open}
          autoHideDuration={5000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={alertInfo.severity}
            sx={{ width: "100%" }}
          >
            {alertInfo.message}
          </Alert>
        </Snackbar>
      </Box>
    </div>
  );
};

export default TeamLeadOperationTable;
