import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  useMediaQuery,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  Box,
  TableBody,
  FormControlLabel,
  Radio,
  Table,
  CircularProgress,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import StatCard from "./StatCard";
import DataTab from "./Datatab";
import Project from "./img/projectIconNew.png";
import ResourseIcon from "./resources.png";
import StatIcon from "./img/statIconNew.png";
import json2mq from "json2mq";
import "./css/main.css";

import DashboardOrders from "./DashboardOrders";
import { FRONTEND_API } from "./urls";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import ExpertTasks from "./ExpertTasks";
import AttendanceTimer from "./AttendanceTimer";
import NoOrders from "./img/no-order-128.png";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { handleDates } from "./constants/utils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Button } from "react-bootstrap";
import axios from "axios";
const MainBody = () => {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const roles = localStorage.getItem("roles");

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [viewStatus, setViewStatus] = useState("month");
  const [reveniew, setReveniew] = useState({});
  const [orderStat, setOrderStat] = useState({
    currentOrder: 0,
    previousOrder: 0,
    percentageChange: 0,
    deviation: "",
  });
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filteredInvoice, setFilteredInvoice] = useState([]);
  const [bargraphStat, setBargraphStat] = useState(null);
  const [bargraphView, setBargraphView] = useState("month");
  const [pendingOrders, setPendingOrders] = useState(0);
  const [pending_Orders, setPending_Orders] = useState([]);

  const [pending_Orders_Count, setPending_Orders_Count] = useState([]);
  const [Assigned_Orders, setAssigned_Orders] = useState(0);
  const [Failed_Orders, setFailed_Orders] = useState(0);
  const [Qc_Orders, setQC_Orders] = useState(0);
  const [Total_Orders, setTotal_Orders] = useState(0);
  const [Unassigned_Orders, setUnassigned_Orders] = useState(0);
  const [completedOrderStat, setCompletedOrderStat] = useState({
    currentOrder: 0,
    previousOrder: 0,
    percentageChange: 0,
    deviation: "",
  });
  const [bargraphMonth, setBargraphMonth] = useState(dayjs().startOf("day"));
  const [bargraphYear, setBarGraphYear] = useState(dayjs().startOf("day"));
  const [clickedCard, setClickedCard] = useState(false);
  const [bargraphValues, setBargraphValues] = useState({});
  const [totalCompletedOrders, setTotalCompletedOrders] = useState(10);
  const [selectedMonth, setSelectedMonth] = useState(dayjs().startOf("day"));
  const [selectedYear, setSelectedYear] = useState(dayjs().startOf("day"));
  const ipadPro = useMediaQuery(
    json2mq({
      maWidth: 1023,
    })
  );
  const [completedOrders, setCompletedOrders] = useState([]);
  const [urgentOrders, setUrgentOrders] = useState([]);
  function createData(name, projectManager, due_date, status, progress) {
    return { name, projectManager, due_date, status, progress };
  }

  const rows = [
    createData("gameApp", "sreejth", "feb 20,2024", "completed", 100),
    createData("TradingApp", "Nizam", "feb 21,2024", "at Risk", 35),
    createData("DataScience", "jabir", "feb 22,2024", "delayed", 50),
  ];
  const dateToday = new Date();

  const fetchOrderData = async (status) => {
    console.log("status inside fetch", status);
    try {
      var formdata = new FormData();
      formdata.append("status", status);
      //formdata.append("user", "student");
      const requestOptions = {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const assignedTotalOrders = await fetch(
        `${FRONTEND_API}getOrdersBySubjectAndStatus?page=${1}&per_page=${100}`,
        requestOptions
      );

      const assignedOrders = await assignedTotalOrders.json();

      const filteredOrders = assignedOrders.orders.filter(
        (order) =>
          handleDates(
            order.expert_type === "lead"
              ? order.order_end_date
              : order.assigned_expert_deadline
          ) === handleDates(dateToday)
      );
      console.log("filteredOrders is here", filteredOrders);
      setUrgentOrders(filteredOrders);
    } catch (rejected) {
      console.log(rejected);
      return null;
    }
  };

  useEffect(() => {
    fetchOrderData("assigned");
    handleSubmitDate();
    handleSubmitDate("bargraph");
  }, []);

  const currentDate = new Date();
  const handleSubmitBargraph = () => {
    handleSubmitDate("bargraph");
  };

  const handleMonthChange = (newDate) => {
    setSelectedMonth(newDate);
  };
  const handleYearChange = (newDate) => {
    setSelectedYear(newDate);
  };
  const handleBarGraphMonth = (newDate) => {
    setBargraphMonth(newDate);
  };
  const handleBarGraphYear = (newDate) => {
    setBarGraphYear(newDate);
  };

  console.log("selected month", selectedMonth.format("MM"));
  console.log("selected Year", selectedYear);

  console.log("");

  console.log("pending orders is here", pendingOrders);
  useEffect(() => {
    // fetchOrderData("pass");
  }, [viewStatus, totalCompletedOrders]);

  console.log("reveniew", reveniew);
  console.log("viewStatus", viewStatus);
  const changeStatusView = (event) => {
    console.log("satusValue", event.target.value);
    setViewStatus(event.target.value);
  };
  const fetchOrders = async () => {
    fetch(FRONTEND_API + "getordersdata", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((rawData) => {
        console.log("haii heel", rawData);
        setOrders(rawData);

        setAssigned_Orders(rawData["assigned_orders_count"]);
        setUnassigned_Orders(rawData["unassigned_orders_count"]);
        setQC_Orders(rawData["qc_orders"]);
        setTotal_Orders(rawData["total_orders"]);
        setFailed_Orders(rawData["failed_orders"]);

        console.log(
          "bargraph then = ",
          bargraphView,
          "  ",
          "viewStatus =",
          viewStatus
        );
        console.log("raw data of all orders", rawData);
        const nullStatusOrders = rawData.filter(
          (order) => order.order_status === "new order"
        );
        console.log("NewOrders", nullStatusOrders);
        const numberOfNullStatusOrders = nullStatusOrders.length;

        console.log(filteredOrders);
        setFilteredOrders(filteredOrders);
      })
      .catch((rejected) => {
        console.log(rejected);
      });
  };

  console.log("bargrap", bargraphView);

  const formatDate = (dateString) => {
    const formattedDate = format(new Date(dateString), "MMM dd, yyyy");
    return formattedDate;
  };

  console.log("orderStat", orderStat);
  const changeOrderStat = (e) => {
    console.log("bargraph dropdown", e.target.value);
    setBargraphView(e.target.value);
  };

  console.log("bargraph value", bargraphStat);
  const unassignedOrder = bargraphStat?.new_order_count || 0;
  const assignedOrders = bargraphStat?.assigned_order_count || 0;
  const orderCompleted = bargraphStat?.completed_count || 0;
  const qcOrders = bargraphStat?.qc_orders || 0;
  const reworkOrders = bargraphStat?.rework_orders || 0;
  const failedOrders = bargraphStat?.failed_orders || 0;
  const totalProjects = bargraphStat?.Orders;

  // percentages
  const unassignedPercentage = (unassignedOrder / totalProjects) * 100;
  const assignedPercentage = (assignedOrders / totalProjects) * 100;
  const qcPercentage = (qcOrders / totalProjects) * 100;
  const reworkPercentage = (reworkOrders / totalProjects) * 100;
  const completedPercentage = (orderCompleted / totalProjects) * 100;
  const failedPercentage = (failedOrders / totalProjects) * 100;
  const totalPercentage =
    (Math.floor(
      unassignedOrder +
        qcOrders +
        assignedOrders +
        reworkOrders +
        orderCompleted +
        failedOrders
    ) /
      totalProjects) *
    100;

  const bargraphViewHandler = (e) => {
    setBargraphView(e.target.value);
  };

  console.log("total projects", totalProjects);
  console.log("total percentage", totalPercentage);

  console.log("completed order stat", completedOrderStat);

  const getPreviousData = async (type, currentOrderCount) => {
    console.log("inside previous data", completedOrderStat);
    let previousMonth = 0;
    let previousYear = 0;
    if (selectedMonth.format("MM") === 1) {
      previousMonth = 12;
      previousYear = selectedYear.format("YYYY") - 1;
    } else {
      previousMonth = selectedMonth.format("MM") - 1;
      previousYear = selectedYear.format("YYYY");
    }
    const formData = new FormData();

    formData.append("month", previousMonth);
    formData.append("year", previousYear);
    const response = await axios.post(
      `${FRONTEND_API}/total_admin_count/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (type === "completed") {
      console.log("previous data response", response.data);
      const completedOrderCount = Number(response.data.completed_count);
      let percentageChangeCompletedOrder = 0;
      if (completedOrderCount > 0) {
        percentageChangeCompletedOrder =
          ((currentOrderCount - completedOrderCount) / completedOrderCount) *
          100;
      }

      let completed_order_deviation =
        percentageChangeCompletedOrder > 0
          ? "increase"
          : percentageChangeCompletedOrder < 0
          ? "decrease"
          : "";
      percentageChangeCompletedOrder = Math.abs(percentageChangeCompletedOrder);
      setCompletedOrderStat({
        currentOrder: currentOrderCount,
        previousOrder: response?.data?.completed_count,
        percentageChange: percentageChangeCompletedOrder,
        deviation: completed_order_deviation,
      });
    } else {
      const totalOrderCount = Number(response.data.Orders);
      let percentageChangeTotalOrders =
        ((currentOrderCount - totalOrderCount) / totalOrderCount) * 100;
      let total_order_deviation =
        percentageChangeTotalOrders > 0
          ? "increase"
          : percentageChangeTotalOrders < 0
          ? "decrease"
          : "";
      percentageChangeTotalOrders = Math.abs(percentageChangeTotalOrders);

      setOrderStat({
        currentOrder: currentOrderCount,
        previousOrder: response?.data?.Orders,
        percentageChange: percentageChangeTotalOrders,
        deviation: total_order_deviation,
      });
    }
  };

  const handleSubmitDate = async (type) => {
    setClickedCard(!clickedCard);
    console.log("handleSubmit");
    const formData = new FormData();
    console.log("selected month inside", selectedMonth.format("MM"));
    console.log("selected year inside", selectedYear.format("YYYY"));
    formData.append(
      "month",
      type === "bargraph"
        ? bargraphMonth.format("MM")
        : selectedMonth.format("MM")
    );
    formData.append(
      "year",
      type === "bargraph"
        ? bargraphYear.format("YYYY")
        : selectedYear.format("YYYY")
    );
    const responseCurrent = await axios.post(
      `${FRONTEND_API}/total_admin_count/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log("response is here from filter", responseCurrent.data);
    if (type === "bargraph") {
      console.log("inside bargraph", responseCurrent.data);
      setBargraphStat(responseCurrent.data);
    } else {
      setCompletedOrderStat({
        ...completedOrderStat,
        currentOrder: Number(responseCurrent.data.completed_count),
      });
      setOrderStat({
        ...orderStat,
        currentOrder: Number(responseCurrent.data.Orders),
      });

      getPreviousData("completed", responseCurrent.data.completed_count);
      getPreviousData("totalOrders", responseCurrent.data.Orders);
    }
    console.log(
      "pending order is here",
      responseCurrent.data.Orders - responseCurrent.data.completed_count
    );
    setPendingOrders(
      Number(responseCurrent?.data?.qc_orders) +
        Number(responseCurrent?.data?.rework_orders)
    );
    // const getPrevFormData = new FormData();

    // const responsePrevious = await axios.post(
    //   `${FRONTEND_API}/total_admin_count`
    // );

    // setOrderStat({
    //   currentOrder: response.data.total_pass_orders,
    //   previousOrder: response.data.total_pass_orders_previous_month,
    //   percentageChange: response.data.percentage_change,
    //   deviation: response.data.deviation,
    // });
  };

  // useEffect(() => {
  //   getPreviousData();
  // }, [clickedCard]);

  console.log("orderStat is here", orderStat);
  console.log("completedOrderStat is here", completedOrderStat);
  console.log("bargraph values", bargraphStat);
  return (
    <Grid
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="start"
      alignItems="center"
      gap="20px"
    >
      <Grid
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="start"
        gap="20px"
        height="15%"
      >
        <Grid
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid width="20%">
            <Typography
              marginRight="20px"
              fontSize="22px"
              fontWeight="semi-bold"
              marginLeft="20px"
            >
              Overview
            </Typography>
          </Grid>
          <Grid
            width={isSmallScreen ? "30%" : "30%"}
            display="flex"
            marginRight="20px"
            marginLeft="20px"
            gap="20px"
            alignItems="center"
            justifyContent="center"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["month"]}
                label="Select Month"
                value={selectedMonth}
                onChange={handleMonthChange}
                renderInput={(params) => <TextField {...params} />}
              />

              <DatePicker
                views={["year"]}
                label="Select Year"
                value={selectedYear}
                onChange={handleYearChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Button
              variant="outlined"
              onClick={handleSubmitDate}
              size="small"
              sx={{ marginRight: 2 }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
        <Grid
          maxWidth="100%"
          display="flex"
          gap="20px"
          marginRight="8px"
          marginLeft="12px"
          justifyContent="center"
          flexWrap="wrap"
          alignItems="center"
        >
          <StatCard
            header={"Total Orders Completed"}
            type={viewStatus}
            totalFigure={
              !isNaN(completedOrderStat?.currentOrder) &&
              completedOrderStat?.currentOrder
            }
            growth={
              completedOrderStat?.previousOrder > 0
                ? completedOrderStat?.deviation
                : ""
            }
            iconName={StatIcon}
            growthPercentage={
              completedOrderStat?.previousOrder > 0
                ? completedOrderStat?.percentageChange
                : ""
            }
          />
          <StatCard
            header={"Orders"}
            type={viewStatus}
            totalFigure={
              !isNaN(orderStat?.currentOrder) && orderStat?.currentOrder
            }
            growth={orderStat?.previousOrder > 0 ? orderStat?.deviation : ""}
            iconName={Project}
            growthPercentage={
              orderStat?.previousOrder > 0 ? orderStat?.percentageChange : ""
            }
          />
          {/* <StatCard
            growth={orderStat?.previousOrder > 0 ? orderStat?.deviation : ""}
            iconName={Project}
            header={"Total Orders"}
            totalFigure={Total_Orders}
          /> */}

          <StatCard header={"Pending Orders"} totalFigure={pendingOrders} />

          {roles === "expert" && <AttendanceTimer />}

          {/* <StatCard
            header={"Projects"}
            totalFigure={"95/100"}
            iconName={Project}
          />
          <StatCard
            header={"Projects"}
            totalFigure={"95/100"}
            iconName={Project}
          /> */}
        </Grid>
      </Grid>
      <Grid
        width="100%"
        display="flex"
        flexDirection={isSmallScreen ? "column" : "row"}
        gap={isSmallScreen ? "14px" : ""}
        alignItems="center"
        justifyContent="space-between"
      >
        <Paper
          sx={{
            width: !isSmallScreen || !isMediumScreen ? "55%" : "100%",
            padding: "14px",
            boxShadow: "3px 3px 13px grey",
            marginLeft: !isSmallScreen ? "20px" : "",
            minHeight: "359px",
            borderRadius: "14px",
          }}
        >
          {roles === "expert" && (
            <ExpertTasks filteredOrders={filteredOrders} />
          )}
          {(roles === "admin" || roles === "lead") && (
            <DashboardOrders urgentOrders={urgentOrders} />
          )}
        </Paper>
        <Paper
          sx={{
            width: !isSmallScreen || !isMediumScreen ? "39%" : "100%",
            padding: "15px",
            display: "flex",
            boxShadow: "3px 3px 13px grey",
            minHeight: "360px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            marginRight: !isSmallScreen ? "20px" : "",
            borderRadius: "14px",
            position: "relative",
          }}
        >
          {/* <CircularProgressBar
            unassignedOrder={unassignedOrder}
            assignedOrders={assignedOrders}
            delayed={qcOrders}
            totalProjects={totalProjects}
          /> */}
          <Grid
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontWeight="bold" fontSize="18px" color="#52545B">
              Orders Overview
            </Typography>
            <Grid
              display="flex"
              marginRight="20px"
              marginLeft="20px"
              gap="20px"
              alignItems="center"
              justifyContent="center"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={["month"]}
                  label="Select Month"
                  value={bargraphMonth}
                  onChange={handleBarGraphMonth}
                  renderInput={(params) => <TextField {...params} />}
                />

                <DatePicker
                  views={["year"]}
                  label="Select Year"
                  value={bargraphYear}
                  onChange={handleBarGraphYear}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <Button
                variant="outlined"
                onClick={handleSubmitBargraph}
                size="small"
                sx={{ marginRight: 2 }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>

          {false ? (
            <div className="multi-graph margin">
              <div
                className="graph"
                style={{
                  "--percentage": `${
                    unassignedPercentage +
                    assignedPercentage +
                    qcPercentage +
                    completedPercentage +
                    failedPercentage +
                    reworkPercentage
                  }`,
                  "--fill": "lightGray",
                }}
              ></div>
              <div
                className="graph"
                style={{
                  "--percentage": `${qcPercentage + assignedPercentage}`,
                  "--fill": "#628DDE",
                }}
              ></div>
              <div
                className="graph"
                style={{
                  "--percentage": `${assignedPercentage}`,
                  "--fill": "#43629B",
                }}
              ></div>
            </div>
          ) : (
            <>
              <img src={NoOrders} alt="no order image" />
              <Grid fontSize="25px" fontWeight="bold"></Grid>
            </>
          )}

          {/* <Box position="absolute">
            {!isNaN(totalPercentage) && (
              <Typography fontSize="28px" fontWeight="bold">
                {totalPercentage.toFixed(0)}%
              </Typography>
            )}
          </Box> */}
          <Grid
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <Grid display="flex" flexDirection="column" alignContent="center">
              {
                <Typography fontSize="22px" fontWeight="bold">
                  {unassignedOrder > 0 ? unassignedOrder : 0}
                </Typography>
              }
              <Typography fontSize="14px" color="#797979">
                Unassigned
              </Typography>
            </Grid>
            <Grid display="flex" flexDirection="column" alignContent="center">
              <Typography fontSize="22px" fontWeight="bold" color="grey">
                {assignedOrders > 0 ? assignedOrders : 0}
              </Typography>
              <Typography fontSize="14px" color="#797979">
                assigned
              </Typography>
            </Grid>
            <Grid display="flex" flexDirection="column" alignContent="center">
              <Typography fontSize="22px" color="#628DDE" fontWeight="bold">
                {qcOrders > 0 ? qcOrders : 0}
              </Typography>
              <Typography fontSize="14px" color="#797979">
                QC
              </Typography>
            </Grid>
            <Grid display="flex" flexDirection="column" alignContent="center">
              <Typography fontSize="22px" fontWeight="bold" color="#43629B">
                {orderCompleted > 0 ? orderCompleted : 0}
              </Typography>
              <Typography fontSize="14px" color="#797979">
                completed
              </Typography>
            </Grid>
            <Grid display="flex" flexDirection="column" alignContent="center">
              <Typography fontSize="22px" color="#628DDE" fontWeight="bold">
                {failedOrders > 0 ? failedOrders : 0}
              </Typography>
              <Typography fontSize="14px" color="#797979">
                Failed
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid
        width="100%"
        display="flex"
        flexDirection={isSmallScreen ? "column" : "row"}
        height="32.5%"
        alignItems="center"
        justifyContent="space-between"
      >
        {/* <Paper
          sx={{
            width: !isSmallScreen ? "55%" : "100%",
            padding: "14px",
            boxShadow: "3px 3px 13px grey",
            marginLeft: !isSmallScreen ? "20px" : "",
            borderRadius: "14px",
            marginBottom: "3px",
          }}
        >
          <DataTab invoices={filteredInvoice} />
        </Paper> */}
        {/* <Paper
          sx={{
            width: "39%",
            padding: "8px",
            marginRight: !isSmallScreen ? "20px" : "",
            borderRadius: "14px",
          }}
        ></Paper> */}
      </Grid>
    </Grid>
  );
};

export default MainBody;
