import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const MessageComponent = ({ message, severity, close}) => {

const [open, setOpen] = React.useState(true);

  return (
    <div>
    
      <Snackbar open={open} onClose = {close} autoHideDuration={5000} >
        <Alert
          severity={severity ? 'success' : 'error'}
          variant="filled"
          sx={{ width: '100%' }}
          autoHideDuration={5000}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default MessageComponent