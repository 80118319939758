import {
  Button,
  Collapse,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  alpha,
  InputBase,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import styled from "@emotion/styled";
import Profile from "./Profile";
import React, { useState } from "react";
import Header from "./Header";
import json2mq from "json2mq";
import MainBody from "./MainBody";
import { ExpandMore } from "@mui/icons-material";

const Dashboard = () => {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openCollapseAccount, setOpenCollapseAccount] = useState(false);
  const [openCollapseTeam, setOpenCollapseTeam] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const ipad = useMediaQuery(
    json2mq({
      maxWidth: 768,
    })
  );
  const toggleClientSubmenu = () => {
    setOpenCollapse(!openCollapse);
  };
  const toggleAccountSubmenu = () => {
    setOpenCollapseAccount(!openCollapseAccount);
  };
  const toggleAccountTeam = () => {
    setOpenCollapseTeam(!openCollapseTeam);
  };

  console.log("ipad", ipad);
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("sm")]: {
        width: "14ch",
        "&:focus": {
          width: "24ch",
        },
      },
    },
  }));

  return (
    <Grid
      width="100%"
      display="flex"
      alignItems="start"
      justifyContent="start"
      minHeight="100vh"
      bgcolor="#E7E9EE"
    >
      <Grid
        width="100%"
        display="flex"
        height="100%"
        flexDirection="column"
        justifyContent="start"
        gap="40px"
        alignContent="center"
      >
        <Grid
          width="100%"
          display="flex"
          height="10%"
          marginTop="15px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            width="80%"
            display="flex"
            flexDirection={isSmallScreen ? "column" : "row"}
            alignItems={isSmallScreen ? "start" : "center"}
            marginRight="20px"
            marginLeft="20px"
            justifyContent="space-between"
          >
            <Typography
              padding="2px"
              fontSize={isMediumScreen ? "20px" : "32px"}
              fontWeight="bold"
              color="black"
            >
              Dashboard
            </Typography>
          </Grid>
          <Grid
            width={isSmallScreen ? "40%" : "30%"}
            display="flex"
            alignItems="center"
            justifyContent="end"
          ></Grid>
        </Grid>
        <MainBody />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
