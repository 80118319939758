import { Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import statIcon from "./img/statIconNew.png";
import ArrowUp from "./upArrow.png";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import ArrowDown from "./arrow-right.png";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
const StatCard = ({
  header,
  growth,
  type,
  totalFigure,
  iconName,
  growthPercentage,
}) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  console.log("header", header);
  console.log("type", type);
  console.log("totalFigure", totalFigure);
  return (
    <Paper
      sx={{
        width: !isSmallScreen ? "330px" : "100%",
        minHeight: "213px",
        boxShadow: "5px 5px 25px grey",
        padding: "18px",
        display: "flex",
        borderRadius: "14px",
        flexDirection: "column",
        bgcolor: "#4767A5",
        gap: "10px",
        justifyContent: "start",
        alignItems: "start",
        margin: "1px",
      }}
    >
      {header === "Pending Orders" ? (
        <PendingActionsIcon
          fontSize="large"
          sx={{ color: "white", fontSize: "47px" }}
        />
      ) : (
        <img src={iconName} style={{ width: "46px", height: "46px" }} />
      )}
      <Typography fontSize={"18px"} color="white">
        {header}
      </Typography>
      <Typography fontSize={"37px"} color="white" fontWeight="bold">
        {header === "Total revenue"
          ? Number(totalFigure).toFixed(1)
          : Number(totalFigure)}
      </Typography>
      <Grid
        width="100%"
        display="flex"
        justifyContent="start"
        gap="4px"
        alignItems="center"
      >
        {growth === "increase" && growthPercentage !== 0 ? (
          <img src={ArrowUp} style={{ width: "16px", height: "16px" }} />
        ) : growth === "decrease" && growthPercentage !== 0 ? (
          <img src={ArrowDown} style={{ width: "16px", height: "16px" }} />
        ) : (
          ""
        )}
        {growthPercentage !== "" ? (
          <Typography fontSize="12px" color="white">
            {header !== "Pending Orders" &&
              `${Number(growthPercentage).toFixed(
                1
              )}% ${growth} from last ${type}`}
          </Typography>
        ) : (
          <Typography fontSize="12px" color="white">
            {header !== "Pending Orders" &&
              `no ${header} available in last ${type}`}
          </Typography>
        )}
      </Grid>
    </Paper>
  );
};

export default StatCard;
