import React, { Component, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Table,
  TableContainer,
  TablePagination,
  TableHead,
  TableBody,
  Button,
  DialogActions,
  Paper,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "./styles/TableStyles";

import { useEffect, useRef } from "react";
import { FRONTEND_API } from "./urls";
import TextField from "@mui/material/TextField";
import {
  DateCalendar,
  DatePicker,
  LocalizationProvider,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { ButtonContainer } from "./styles/style";

import IconButton from "@mui/material/IconButton";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import MessageComponent from "./MessageComponent";
import CloseIcon from "@mui/icons-material/Close";
import { concat, update } from "lodash";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";

const DPRConsole = ({ open, handleClose, userId, getAllData, dprType }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [dprDetails, setDprDetails] = useState([]);
  const token = localStorage.getItem("token");
  const roles = localStorage.getItem("roles");

  const [editMode, setEditMode] = useState(false);
  const [editedDprDetails, setEditedDprDetails] = useState([]);

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const buttonRef = useRef(null);
  const today = dayjs();

  const [severity, setSeverity] = useState(null);
  const [alertContent, setAlertContent] = useState("");

  const [months, setMonths] = useState([
    { month: "January", value: 1 },
    { month: "February", value: 2 },
    { month: "March", value: 3 },
    { month: "April", value: 4 },
    { month: "May", value: 5 },
    { month: "June", value: 6 },
    { month: "July", value: 7 },
    { month: "August", value: 8 },
    { month: "September", value: 9 },
    { month: "October", value: 10 },
    { month: "November", value: 11 },
    { month: "December", value: 12 },
  ]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const currentYear = new Date().getFullYear();
  const fetchData = async (monthValue) => {
    const rawData = await fetchDpr(monthValue);
    if (rawData) {
      console.log("dpr get request ", rawData);
      setDprDetails(rawData);
      // Extract unique months from rawData
      // const uniqueMonths = [
      //   ...new Set(
      //     rawData.map((dpr) =>
      //       new Date(
      //         roles === "lead" && dprType === "adminLeadDpr"
      //           ? dpr.deadline
      //           : roles === "lead" || roles === "expert"
      //           ? dpr.assigned_expert_deadline
      //           : roles === "admin" && dprType === "teamMemberDpr"
      //           ? dpr.assigned_expert_deadline
      //           : dpr.deadline
      //       ).toLocaleString("default", {
      //         month: "long",
      //       })
      //     )
      //   ),
      // ];

      // Set default selected month
      const currentMonth = new Date().toLocaleString("default", {
        month: "long",
      });

      // Set default selected month
    }
  };
  useEffect(() => {
    fetchData(selectedMonth);
  }, [selectedMonth]);

  const fetchDpr = async (monthValue) => {
    try {
      const formData = new FormData();
      formData.append("month", monthValue);
      formData.append("year", currentYear);
      const requestOptions = {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(
        FRONTEND_API + "getDpr/" + userId,
        requestOptions
      );
      const rawData = await response.json();
      const sortedData = rawData.sort(
        (a, b) => new Date(handleDynamicKey(a)) - new Date(handleDynamicKey(b))
      );
      setDprDetails(sortedData);
    } catch (error) {
      console.error("Error fetching DPR data:", error);
    }
  };
  const handleDate = (expertDate) => {
    var formattedDate = null;
    if (expertDate != null) {
      const dateObject = new Date(expertDate);
      formattedDate = dateObject.toDateString();
    }
    return formattedDate;
  };

  // const [editDprDialog, setEditDprDialog] = useState(false);

  const saveDpr = () => {
    console.log("edited dpr details is here", editedDprDetails);
    const data = editedDprDetails.map((item) => {});
    const jsonData = JSON.stringify({ updates: editedDprDetails });

    var requestOptions = {
      method: "PUT",
      body: jsonData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    let status = "";
    fetch(FRONTEND_API + "editDpr", requestOptions)
      .then((response) => {
        if (response.status == 400) {
          status = "400";
          return response.json();
        } else if (response.status == 200) {
          status = "200";
          return response.json();
        }
      })

      .then((result) => {
        //JSON.parse(response._bodyText)
        if (status == "200") {
          setAlertContent(result.message);
          setEditMode(false);
          setDialogOpen(true);
          getAllData();
          fetchData(selectedMonth);
          setSeverity(true);
        } else {
          setAlertContent(result.message);
          setDialogOpen(true);
          setSeverity(false);
        }
      })
      .catch((rejected) => {
        console.log(rejected);
      });

    // Perform saving logic here, for example, updating the backend or state
    // After saving, you might want to close the dialog
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const [dateChangeIndex, setDateChangeIndex] = useState();
  const handleButtonClick = (index) => {
    console.log("index received", index);
    setDateChangeIndex(index);
    if (openDatePicker) {
      setOpenDatePicker(false);
    } else {
      setOpenDatePicker(true);
    }
  };

  const teamLeadKeys = [
    "deadline",
    ...Array.from({ length: 6 }, (_, i) => `team_deadline${i + 1}`),
  ];

  // Define keys for expert deadlines (assigned_expert_deadline, assigned_expert_deadline1 to assigned_expert_deadline6)
  const expertDeadlineKeys = [
    "assigned_expert_deadline",
    ...Array.from({ length: 6 }, (_, i) => `assigned_expert_deadline${i + 1}`),
  ];

  // Function to handle dynamic key checks
  const handleDynamicKey = (object) => {
    // Check team deadlines (deadline, team_deadline1 to team_deadline6)
    for (let key of teamLeadKeys) {
      if (object[key]) {
        return handleDate(object[key]);
      }
    }

    // Check assigned expert deadlines (assigned_expert_deadline, assigned_expert_deadline1 to assigned_expert_deadline6)
    for (let key of expertDeadlineKeys) {
      if (object[key]) {
        return handleDate(object[key]);
      }
    }

    // Default fallback to `deadline`
    return handleDate(object.deadline);
  };

  const handleDeadlineChange = (newDeadline, keyToChange, selectedIndex) => {
    console.log("Selected index:", selectedIndex);
    console.log("New deadline:", newDeadline);
    console.log("Key to change:", keyToChange);

    // Assuming newDeadline is in a valid date format string
    const deadlineDate = new Date(newDeadline);

    // Format the date into YYYY-M-D
    const formattedDeadline = `${deadlineDate.getFullYear()}-${
      deadlineDate.getMonth() + 1
    }-${deadlineDate.getDate()}`;

    console.log("Formatted deadline:", formattedDeadline);

    const updatedDetails = editedDprDetails.map((item, index) => {
      if (selectedIndex === index) {
        return { ...item, [keyToChange]: formattedDeadline };
      }
      return item;
    });

    setEditedDprDetails(updatedDetails);
  };

  const handleWordCountChange = (event, keyToChange, selectedIndex) => {
    console.log("Selected index:", selectedIndex);
    console.log("New deadline:", event);
    console.log("Key to change:", keyToChange);

    const updatedDetails = editedDprDetails.map((item, index) => {
      if (selectedIndex === index) {
        return { ...item, [keyToChange]: event.target.value };
      }
      return item;
    });
    console.log("updated details is here", updatedDetails);

    setEditedDprDetails(updatedDetails);
  };

  console.log("edited dpr details", editedDprDetails);

  const handleRemoveRow = (row) => {
    console.log(row);
    var formdata = new FormData();
    formdata.append("expert_id", userId);

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    let status = "";
    fetch(FRONTEND_API + "removeAssignedOrder/" + concat(row), requestOptions)
      .then((response) => {
        if (response.status == 404) {
          status = "400";
          return response.json();
        } else if (response.status == 200) {
          status = "200";
          return response.json();
        }
      })
      .then((result) => {
        //JSON.parse(response._bodyText)
        if (status == "200") {
          fetchDpr()
            .then(() => {
              setDialogOpen(true);
              setSeverity(true);
              fetchData(selectedMonth);
            })
            .catch((error) => {
              console.error("Error fetching DPR:", error);
              setDialogOpen(true);
              setSeverity(false);
            });
        } else {
          setAlertContent(result.message);
          setDialogOpen(true);
          setSeverity(false);
        }
      })
      .catch((error) => {
        setAlertContent(error);
        setDialogOpen(true);
        setSeverity(false);
      });
  };

  const handleMonthChange = (e) => {
    console.log("selected month", e.target.value);
    setSelectedMonth(e.target.value);
    fetchData(e.target.value);
  };

  // Filter dprDetails based on selectedMonth
  console.log("dprDetails", dprDetails);
  const filteredDprDetails = dprDetails.filter(
    (dpr) =>
      new Date(
        roles === "lead" && dprType === "adminLeadDpr"
          ? dpr.deadline
          : roles === "lead" || roles === "expert"
          ? dpr.assigned_expert_deadline
          : roles === "admin" && dprType === "teamMemberDpr"
          ? dpr.assigned_expert_deadline
          : dpr.deadline
      ).toLocaleString("default", { month: "long" }) === selectedMonth
  );

  const sortDprDetails = filteredDprDetails.sort((a, b) => {
    return (
      new Date(
        roles === "lead" && dprType === "adminLeadDpr"
          ? a.deadline
          : roles === "lead" || roles === "expert"
          ? a.assigned_expert_deadline
          : roles === "admin" && dprType === "teamMemberDpr"
          ? a.assigned_expert_deadline
          : a.deadline
      ) -
      new Date(
        roles === "lead" && dprType === "adminLeadDpr"
          ? b.deadline
          : roles === "lead" || roles === "expert"
          ? b.assigned_expert_deadline
          : roles === "admin" && dprType === "teamMemberDpr"
          ? b.assigned_expert_deadline
          : b.deadline
      )
    );
  });

  console.log("sorted dpr details", sortDprDetails);

  const editDpr = () => {
    setEditMode(true);
    console.log("edit details from dpr");

    // Assuming dprDetails is your initial data array, you can copy it here for editing
    setEditedDprDetails([...dprDetails]);
  };

  const CustomTableCell = styled(TableCell)({
    backgroundColor: "#00B050",
    color: "#fff",
    borderRight: "1px solid #fff",
    fontWeight: "bold",
  });

  console.log("dpr details is here", dprDetails);
  console.log("condition is here");
  const leadWordCount = [
    "no_of_words",
    "no_of_word1",
    "no_of_word2",
    "no_of_word3",
    "no_of_word4",
    "no_of_word5",
    "no_of_word6",
  ];
  const memberWordCount = [
    "expert_no_of_words",
    "expert_no_of_word1",
    "expert_no_of_word2",
    "expert_no_of_word3",
    "expert_no_of_word4",
    "expert_no_of_word5",
    "expert_no_of_word6",
  ];
  return (
    <Box sx={{ width: "90%" }}>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            minWidth: 1100,
            // Add other custom styles here
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>DPR</DialogTitle>

        <DialogContent>
          <select value={selectedMonth} onChange={handleMonthChange}>
            {months.map((month) => (
              <option key={month} value={month.value}>
                {month.month}
              </option>
            ))}
          </select>
          <TableContainer
            component={Paper}
            sx={{
              marginBottom: 6,
            }}
            aria-label="customized table"
          >
            <Table aria-label="simple table">
              <TableHead sx={{ backgroundColor: "customGreenColor" }}>
                <StyledTableRow>
                  <CustomTableCell>Order ID</CustomTableCell>
                  {/* <CustomTableCell>Assigned On</CustomTableCell> */}
                  <CustomTableCell>Deadline</CustomTableCell>
                  <CustomTableCell>Words</CustomTableCell>
                  <CustomTableCell>Remarks</CustomTableCell>
                  <CustomTableCell>Incentive</CustomTableCell>
                  <CustomTableCell>Comment</CustomTableCell>
                  <CustomTableCell></CustomTableCell>
                </StyledTableRow>
              </TableHead>
              {editMode ? (
                <TableBody>
                  {editedDprDetails.map((dpr, index) => {
                    // Here, you need to determine the current key (team_deadlineX or assigned_expert_deadlineX)
                    const currentTeamDeadlineKey = teamLeadKeys.find(
                      (key) => dpr[key]
                    );
                    console.log("current dpr is here", dpr);
                    const currentExpertDeadlineKey = expertDeadlineKeys.find(
                      (key) => dpr[key]
                    );
                    const currentmemberWordCount = memberWordCount.find(
                      (key) => dpr[key]
                    );
                    const currentLeadWordCount = leadWordCount.find(
                      (key) => dpr[key]
                    );
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell>{dpr.order_id}</StyledTableCell>
                        <StyledTableCell>
                          {" "}
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker
                                label="Deadline"
                                value={dayjs(
                                  dpr[currentTeamDeadlineKey] ||
                                    dpr[currentExpertDeadlineKey]
                                )}
                                onChange={(e) => {
                                  handleDeadlineChange(
                                    dayjs(e).format(
                                      "ddd, DD MMM YYYY HH:mm:ss [GMT]"
                                    ),
                                    currentTeamDeadlineKey ||
                                      currentExpertDeadlineKey,
                                    index
                                  );
                                  setOpenDatePicker(false);
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </StyledTableCell>
                        {/* Additional editable fields */}
                        <StyledTableCell>
                          {console.log("edit value", dpr)}
                          <TextField
                            sx={{ width: 80 }}
                            value={
                              dpr.expert_type === "expert"
                                ? dpr[currentmemberWordCount]
                                : dpr[currentLeadWordCount]
                            }
                            onChange={(e) =>
                              handleWordCountChange(
                                e,
                                currentmemberWordCount || currentLeadWordCount,
                                index
                              )
                            }
                          />
                        </StyledTableCell>

                        <StyledTableCell>
                          <TextField
                            sx={{ width: 180 }}
                            value={dpr.remarks}
                            onChange={(e) => {
                              const updatedDprDetails = [...editedDprDetails];
                              updatedDprDetails[index].remarks = e.target.value;
                              setEditedDprDetails(updatedDprDetails);
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            sx={{ width: 180 }}
                            value={dpr.incentive}
                            onChange={(e) => {
                              const updatedDprDetails = [...editedDprDetails];
                              updatedDprDetails[index].incentive =
                                e.target.value;
                              setEditedDprDetails(updatedDprDetails);
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            sx={{ width: 180 }}
                            value={dpr.comments}
                            onChange={(e) => {
                              const updatedDprDetails = [...editedDprDetails];
                              updatedDprDetails[index].comments =
                                e.target.value;
                              setEditedDprDetails(updatedDprDetails);
                            }}
                          />
                        </StyledTableCell>

                        {/* Add more fields as needed */}
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  {console.log("filtered data", filteredDprDetails)}
                  {dprDetails.map((dpr, index) => {
                    // Define keys for team deadlines (team_deadline1 to team_deadline6)
                    const currentTeamDeadlineKey = teamLeadKeys.find(
                      (key) => dpr[key]
                    );
                    console.log("current dpr is here", dpr);
                    const currentExpertDeadlineKey = expertDeadlineKeys.find(
                      (key) => dpr[key]
                    );
                    const currentmemberWordCount = memberWordCount.find(
                      (key) => dpr[key]
                    );
                    const currentLeadWordCount = leadWordCount.find(
                      (key) => dpr[key]
                    );
                    console.log("current word count: ", currentmemberWordCount);
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell>{dpr.order_id}</StyledTableCell>

                        <StyledTableCell>
                          {handleDynamicKey(dpr)}
                        </StyledTableCell>

                        <StyledTableCell>
                          {dpr.expert_type === "expert"
                            ? dpr[currentmemberWordCount]
                            : dpr[currentLeadWordCount]}
                        </StyledTableCell>
                        <StyledTableCell>{dpr.remarks}</StyledTableCell>
                        <StyledTableCell>{dpr.incentive}</StyledTableCell>
                        <StyledTableCell>{dpr.comments}</StyledTableCell>

                        <StyledTableCell>
                          <CloseIcon onClick={() => handleRemoveRow(dpr.id)} />
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          {(roles === "lead" && dprType === "adminLeadDpr") ||
          roles === "expert" ? (
            <></>
          ) : editMode ? (
            <Button onClick={saveDpr}>Save</Button>
          ) : (
            <Button sx={{ color: "#00B050" }} onClick={editDpr}>
              Edit
            </Button>
          )}
          <Button sx={{ color: "#00B050" }} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {dialogOpen && (
        <MessageComponent
          message={alertContent}
          severity={severity}
          close={handleCloseDialog}
        />
      )}
    </Box>
  );
};

export default DPRConsole;
