import {
  Grid,
  TableCell,
  Typography,
  useMediaQuery,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableContainer,
  Box,
} from "@mui/material";
import { format } from "date-fns";
import json2mq from "json2mq";
import React from "react";
import { Link } from "react-router-dom";
import { handleDates } from "./constants/utils";

const DashboardOrders = ({ urgentOrders }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const currentDate = new Date();
  console.log("urgent orders inside table", urgentOrders);
  const formatDate = (dateString) => {
    return format(new Date(dateString), "MMM dd, yyyy");
  };

  // Check if orders is not null and is an array
  if (
    !urgentOrders ||
    !Array.isArray(urgentOrders) ||
    urgentOrders.length === 0
  ) {
    return (
      <Typography variant="body1">
        No orders available or invalid data format for orders.
      </Typography>
    );
  }

  return (
    <Grid
      width="100%"
      display="flex"
      flexDirection="column"
      alignContent="center"
    >
      <Grid
        width="100%"
        display="flex"
        flexDirection={isSmallScreen ? "column" : "row"}
        gap={isSmallScreen ? "13px" : ""}
        justifyContent="space-between"
        alignItems={!isSmallScreen ? "center" : "start"}
      >
        <Grid width={!isSmallScreen ? "30%" : "100%"} marginLeft="15px">
          <Typography fontSize="16px" fontWeight="bold" color="#52545B">
            Order Need Attention
          </Typography>
        </Grid>
      </Grid>
      <TableContainer component={Box}>
        <Table
          sx={{ maxWidth: 800, overflow: "scroll" }}
          aria-label="caption table"
        >
          <TableHead>
            <TableRow sx={{ height: "20px" }}>
              <TableCell>
                <Typography fontSize="14px" fontWeight="bold">
                  Order Id
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography fontSize="14px" fontWeight="bold">
                  Order Deadline
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography fontSize="14px" fontWeight="bold">
                  Expert Deadline
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontSize="14px" fontWeight="bold">
                  Expert name
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography fontSize="14px" fontWeight="bold">
                  Client name
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography fontSize="14px" fontWeight="bold">
                  Comments
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {urgentOrders?.slice(0, 4).map((row) => (
              <TableRow key={row.name} sx={{ height: "20px" }}>
                <TableCell component="th" sx={{ padding: "9px" }} scope="row">
                  <Typography fontSize="14px" color="#515151">
                    {row.id}
                  </Typography>
                </TableCell>
                <TableCell sx={{ padding: "9px" }} align="center">
                  <Typography fontSize="14px" color="#515151">
                    {handleDates(row.order_create_deadline)}
                  </Typography>
                </TableCell>
                <TableCell sx={{ padding: "9px" }} align="right">
                  <Typography fontSize="14px">
                    {row.expert_type === "expert"
                      ? handleDates(row.assigned_expert_deadline)
                      : handleDates(row.order_end_date)}
                  </Typography>
                </TableCell>
                <TableCell sx={{ padding: "9px" }} align="center">
                  <Typography
                    fontSize="14px"
                    padding="6px"
                    paddingX={"9px"}
                    borderRadius="20px"
                  >
                    {row.expert_id}
                  </Typography>
                </TableCell>
                <TableCell sx={{ padding: "9px" }} align="left">
                  <Typography
                    sx={{
                      padding: "9px",
                    }}
                    align="start"
                  >
                    {row.client_id}
                  </Typography>
                </TableCell>

                <TableCell
                  sx={{
                    padding: "9px",
                  }}
                  align="center"
                >
                  <Typography fontSize="14px" color="#515151">
                    {row.comments}
                  </Typography>

                  {/* <Typography fontSize='14px'>{row.progress}</Typography> */}
                </TableCell>
              </TableRow>
            ))}
            <TableRow sx={{ height: "10px" }}>
              <Link
                color="primary"
                to={`/order-management?tab=assigned`}
                sx={{ mt: 3, minWidth: "200px" }}
              >
                <Typography sx={{ minWidth: "150px" }}>
                  See more orders
                </Typography>
              </Link>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default DashboardOrders;
