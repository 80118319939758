import { styled } from '@mui/system';

export const ClientBox = styled('div')({
   padding: '10px',
   background: '#FBF1F7',
   display: 'flex',
   flexDirection: 'column',
   minHeight: 'auto'
  });

export const ClinetInfoBox = styled('div')({
    padding: '5px',
    marginTop: '8px',
    border: '1px solid',
    borderColor: '#000',
    borderRadius: '3px', 
    background: '#FFF',
    display: 'block',

});

export const ButtonInfoBox = styled('div')({
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
});


export const InfoBox = styled('div')({
    padding: '5px',
    margin: '8px 0',
    display: 'block',
});