import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { Link, useLocation } from "react-router-dom";
import { Collapse, ListItem, Typography } from "@mui/material";
import {
  AccountBox,
  ChildFriendlyOutlined,
  ExpandLess,
  ExpandMore,
  ManageAccounts,
  People,
  PeopleAltOutlined,
  PersonAddAlt,
  Receipt,
  RequestQuote,
  School,
  Star,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import CrownIcon from "@mui/icons-material/EmojiEvents";
import SpeedIcon from "@mui/icons-material/Speed";
import Person4 from "@mui/icons-material/Person4";

export default function SideItems({ isOpen }) {
  const [openCollapse, setOpenCollapse] = React.useState({});
  const role = localStorage.getItem("roles");
  const location = useLocation();
  console.log("role from sidebar", role);
  const toggleSubmenu = (index) => {
    setOpenCollapse({ ...openCollapse, [index]: !openCollapse[index] });
  };

  const adminSideBar = [
    {
      text: "Dashboard",
      icon: <SpeedIcon />,
      link: "/dashboard",
    },
    {
      text: "Manage Orders",
      icon: <ChildFriendlyOutlined />,
      link: "/order-management",
    },
    {
      text: "Clients",
      icon: <PeopleAltOutlined />,
      submenu: [
        { text: "Students", icon: <School />, link: "/UpdateClientdata" },
        { text: "Vendors", icon: <Person4 />, link: "/Updatevonder" },
      ],
    },
    // {
    //     text: 'Accounting',
    //     icon: <AccountBox />,
    //     submenu: [
    //         { text: 'Invoices', icon: <Receipt />, link: '/order-history' },
    //         {
    //             text: 'Expense Management',
    //             icon: <RequestQuote />,
    //             link: '/expense-manage',
    //         },
    //     ],
    // },
    {
      text: "Manage Team",
      icon: <ManageAccounts />,
      submenu: [
        { text: "Experts", icon: <Star />, link: "/Updatetutors" },
        { text: "OTMs", icon: <People />, link: "/updateotm" },
        { text: "Team Leads", icon: <CrownIcon />, link: "/teamLead" },
      ],
    },
    {
      text: "Admin Tasks",
      isSubheader: true,
    },
    {
      text: "Register User",
      icon: <PersonAddAlt />,
      link: "/register",
    },
  ];

  const otherSidebar = [
    {
      text: "Dashboard",
      icon: <SpeedIcon />,
      link: "/dashboard",
    },
    {
      text: "Manage Orders",
      icon: <ChildFriendlyOutlined />,
      link: "/order-management",
    },
  ];

  const hrSideBar = [
    {
      text: "Dashboard",
      icon: <SpeedIcon />,
      link: "/dashboard",
    },
    {
      text: "Attandance Overview",
      icon: <ChildFriendlyOutlined />,
      link: "/attendanceOverview",
    },
    {
      text: "Manage Team",
      icon: <ManageAccounts />,
      submenu: [
        { text: "Experts", icon: <Star />, link: "/Updatetutors" },
        { text: "OTMs", icon: <People />, link: "/updateotm" },
        { text: "Team Leads", icon: <CrownIcon />, link: "/teamLead" },
      ],
    },
  ];

  const leadSidebar = [
    {
      text: "Dashboard",
      icon: <SpeedIcon />,
      link: "/dashboard",
    },
    {
      text: "Manage Orders",
      icon: <ChildFriendlyOutlined />,
      link: "/order-management",
    },
    {
      text: "Manage Team",
      icon: <ManageAccounts />,
      submenu: [
        { text: "Experts", icon: <Star />, link: "/Updatetutors" },

        { text: "Team Leads", icon: <CrownIcon />, link: "/teamLead" },
      ],
    },
  ];
  const useSideBar =
    role === "admin"
      ? adminSideBar
      : role === "hr"
      ? hrSideBar
      : role === "lead"
      ? leadSidebar
      : otherSidebar;

  return (
    <div>
      {useSideBar.map((item, index) => {
        const isSelected = item.link === location.pathname;
        if (item.isSubheader) {
          return (
            <ListItemButton
              key={index}
              sx={{
                bgcolor: isSelected ? "#FFFFFF" : "",
                fontWeight: isSelected ? "bold" : "normal",
                borderRadius: "10px",
                color: "white",
                "&:hover": {
                  bgcolor: !isSelected ? "#5A86D6" : "white",
                },
                width: isOpen ? "240px" : "60px",
                marginLeft: isOpen ? "18px" : "2px",
              }}
            >
              <ListItemIcon sx={{ color: "white" }}></ListItemIcon>
              <ListItemText>
                <Typography fontWeight="bold" color="white">
                  {item.text}
                </Typography>
              </ListItemText>
            </ListItemButton>
          );
        } else if (item.submenu) {
          return (
            <React.Fragment key={index}>
              <ListItemButton
                onClick={() => toggleSubmenu(index)}
                disableRipple
                sx={{
                  bgcolor: isSelected ? "#FFFFFF" : "",
                  fontWeight: isSelected ? "bold" : "normal",
                  borderRadius: "10px",
                  color: isSelected ? "#4767A5" : "inherit",
                  "&:hover": {
                    bgcolor: !isSelected ? "#5A86D6" : "white",
                  },
                  width: isOpen ? "240px" : "60px",
                  marginLeft: isOpen ? "18px" : "2px",
                }}
              >
                <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>
                <ListItemText>
                  <Typography fontWeight="bold" color="white">
                    {item.text}
                  </Typography>
                </ListItemText>
                {openCollapse[index] ? (
                  <ExpandLess color="white" sx={{ color: "white" }} />
                ) : (
                  <ExpandMore color="white" sx={{ color: "white" }} />
                )}
              </ListItemButton>
              <Collapse in={openCollapse[index]} timeout="auto" unmountOnExit>
                {item.submenu.map((subitem, subindex) => {
                  const isSubitemSelected = subitem.link === location.pathname;
                  console.log("isSubitemSelected", isSubitemSelected);
                  return (
                    <Link key={subindex} to={subitem.link}>
                      {console.log("subitem selected", isSubitemSelected)}
                      <ListItemButton
                        disableRipple
                        sx={{
                          display: "flex",
                          paddingLeft: isOpen ? "30px" : "16px",
                          justifyContent: "start",
                          borderRadius: "10px",
                          fontWeight: "bold",
                          color: "#FFFFFF",
                          width:
                            isOpen && subitem.text === "Expense Management"
                              ? "250px"
                              : isOpen
                              ? "240px"
                              : "60px",
                          marginLeft: isOpen ? "18px" : "2px",
                          bgcolor: isSubitemSelected ? "#FFFFFF" : "inherit",
                          "&:hover": {
                            bgcolor: !isSubitemSelected ? "#5A86D6" : "white",
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            color: isSubitemSelected ? "#4767A5" : "white",
                            marginRight: !isOpen && "10px",
                          }}
                        >
                          {subitem.icon}
                        </ListItemIcon>
                        <ListItemText>
                          <Typography
                            fontWeight="bold"
                            color={isSubitemSelected ? "#4767A5" : "white"}
                          >
                            {subitem.text}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </Link>
                  );
                })}
              </Collapse>
            </React.Fragment>
          );
        } else {
          return (
            <Link key={index} to={item.link}>
              <ListItemButton
                disableRipple
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  bgcolor: isSelected ? "#FFFFFF" : "",

                  color: isSelected ? "#4767A5" : "white",
                  "&:hover": {
                    bgcolor: !isSelected ? "#5A86D6" : "white",
                  },
                  borderRadius: "10px",
                  width: isOpen ? "240px" : "60px",
                  marginLeft: isOpen ? "18px" : "2px",
                }}
              >
                <ListItemIcon sx={{ color: isSelected ? "#4767A5" : "white" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    fontWeight="bold"
                    color={isSelected ? "#4767A5" : "white"}
                  >
                    {item.text}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </Link>
          );
        }
      })}
    </div>
  );
}
