import { Dialog } from "@mui/material";
import { BootstrapDialog } from "./styles/DialogStyle";
import CloseIcon from "@mui/icons-material/Close";

import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import {
  FormControl,
  MenuItem,
  InputLabel,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Container,
} from "@mui/material";
import Select from "@mui/material/Select";
import { Alert } from "@mui/material";
import { useState } from "react";
import { FRONTEND_API } from "./urls";
import { MuiPhone } from "./MuiPhone";
import IconButton from "@mui/material/IconButton";
import { ToastContainer, toast } from "react-toastify";

const AddClientDialog = ({ open, handleClose, fetchClientData }) => {
  const [user, setUser] = React.useState("");

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState("");
  const [alertContent, setAlertContent] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [severity, setSeverity] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [University, setUniversity] = useState("");
  const [Business_name, setBusiness_name] = useState("");
  const token = localStorage.getItem("token");

  const [clientFirstNameValid, setClientFirstNameValid] = useState(null);
  const [clientLastNameValid, setClientLastNameValid] = useState(null);
  const [clientPhoneValid, setClientPhoneValid] = useState(null);
  const [clientEmailValid, setClientEmailValid] = useState(null);
  const [clientUnivValid, setClientUnivValid] = useState(null);
  const [clientBusinessValid, setClientBusinessValid] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);

  const validateFirstName = (value) => value.length >= 3;
  const validatePhone = (value) => !isNaN(value) && value.length == 10;
  const validateEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  const validateLastName = (value) => value.length >= 1;
  const validateUniv = (value) => value.length >= 4;
  const validateBusiness = (value) => value.length >= 4;

  const resetValidationFields = () => {
    setClientFirstNameValid(null);
    setClientLastNameValid(null);
    setClientPhoneValid(null);
    setClientEmailValid(null);
    // setAlert(false);
    // setStatus("");
    setClientUnivValid(null);
    setClientBusinessValid(null);
  };

  const resetFormFields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setUniversity("");
    setBusiness_name("");
  };

  const onFirstNameChange = (event) => {
    const newValue = event.target.value;
    setFirstName(newValue);
    setClientFirstNameValid(validateFirstName(newValue));
  };

  const onLastNameChange = (event) => {
    const newValue = event.target.value;
    setLastName(newValue);
    //setClientLastNameValid(validateLastName(newValue));
  };
  const onEmailChange = (event) => {
    const newValue = event.target.value;
    setEmail(newValue);
    setClientEmailValid(validateEmail(newValue));
  };

  const onPhoneChange = (event) => {
    const newValue = event.target.value;
    setPhone(newValue);
    setClientPhoneValid(validatePhone(newValue));
  };

  const handleClientUnivChange = (event) => {
    const newValue = event.target.value;
    setUniversity(newValue);
    setClientUnivValid(validateUniv(newValue));
  };

  const handleClientBusinessChange = (event) => {
    const newValue = event.target.value;
    setBusiness_name(newValue);
    setClientBusinessValid(validateBusiness(newValue));
  };

  const handleToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleSubmit = (event) => {
    console.log("handle submit is working");
    event.preventDefault();
    /* if (clientFirstNameValid ) {

        
        } */
    if (user == "student") {
      console.log("inside student");
      var formdata = new FormData();
      formdata.append("Client_name", firstName + lastName);
      formdata.append("Client_contact", phone);
      formdata.append("Client_email", email);
      formdata.append("Client_status", "student");
      formdata.append("University", University);
      formdata.append("Student_login", email);
      formdata.append("Student_password", password);

      var requestOptions = {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      let respCode = 0;
      fetch(FRONTEND_API + "addclient", requestOptions)
        .then((response) => {
          if (response.status == 200) {
            respCode = Number(200);
            return response.json();
          } else {
            respCode = response.status;
            return response.json();
          }
        })
        .then((result) => {
          if (respCode === 200) {
            console.log("resonse is here");
            console.log("message", result.message);
            setAlert(true);
            setAlertContent(result.message);
            handleToastMessage(result.message, "success");
            setStatus("200");
            fetchClientData(user);
            // handleClose();
            resetFormFields();
            resetValidationFields();

            setSeverity(true);
          } else {
            setAlertContent(result.message);
            setAlert(true);
            handleToastMessage(result.message, "error");
            resetFormFields();
            resetValidationFields();
            setSeverity(false);
          }
        })
        .catch((error) => {
          setAlert(true);
          setAlertContent("Error Occured");
        });
    } else if (user == "vendor") {
      var formdata = new FormData();
      formdata.append("Client_name", firstName + lastName);
      formdata.append("Client_contact", phone);
      formdata.append("Client_email", email);
      formdata.append("Client_status", "vendor");
      formdata.append("Business_name", Business_name);

      var requestOptions = {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      let respCode = 0;
      fetch(FRONTEND_API + "addclient", requestOptions)
        .then((response) => {
          if (response.status == 200) {
            respCode = 200;
            return response.json();
          } else {
            respCode = response.status;
            return response.json();
          }
        })
        .then((result) => {
          if (respCode === 200) {
            setAlertContent(result.message);
            setAlert(true);
            setStatus("200");
            handleToastMessage(result.message, "success");
            fetchClientData(user);
            resetFormFields();
            resetValidationFields();
            fetchClientData();
            setSeverity(true);
          } else {
            setAlertContent(result.message);
            setAlert(true);
            handleToastMessage(result.message, "error");
            resetFormFields();
            resetValidationFields();
            setSeverity(false);
          }
        })
        .catch((error) => alert("error", error));
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleChange = (event) => {
    setUser(event.target.value);
  };

  return (
    <BootstrapDialog open={open} onClose={handleClose}>
      <DialogTitle>Add Client</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid>
          <Grid>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Add Clients
              </Typography>

              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      value={firstName}
                      onChange={onFirstNameChange}
                      error={clientFirstNameValid == false}
                      helperText={
                        clientFirstNameValid == false && "Invalid First Name"
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      value={lastName}
                      onChange={onLastNameChange}
                      error={clientLastNameValid == false}
                      helperText={
                        clientLastNameValid == false && "Invalid Last Name"
                      }
                    />
                  </Grid>
                </Grid>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={onEmailChange}
                  error={clientEmailValid == false}
                  helperText={clientEmailValid == false && "Invalid Email"}
                />
                <FormControl fullWidth sx={{ marginTop: 2 }}>
                  <MuiPhone
                    defaultCountry="ua"
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ marginTop: 2 }}>
                  <InputLabel id="demo-simple-select-label">User</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={user}
                    label="user"
                    onChange={handleChange}
                  >
                    <MenuItem value="student">Student</MenuItem>
                    <MenuItem value="vendor">Vendor</MenuItem>
                  </Select>
                  {user ? (
                    <p>Selected user is: {user}</p>
                  ) : (
                    <p>No user is selected</p>
                  )}
                </FormControl>
                {user == "student" && (
                  <FormControl fullWidth sx={{ marginTop: 1 }}>
                    <TextField
                      margin="normal"
                      fullWidth
                      value={University}
                      onChange={handleClientUnivChange}
                      variant="outlined"
                      error={clientUnivValid == false}
                      helperText={
                        clientUnivValid == false && "Invalid University Name"
                      }
                      id="university"
                      label="University"
                      name="text"
                    />
                  </FormControl>
                )}
                {user == "vendor" && (
                  <FormControl fullWidth sx={{ marginTop: 1 }}>
                    <TextField
                      margin="normal"
                      fullWidth
                      value={Business_name}
                      onChange={handleClientBusinessChange}
                      variant="outlined"
                      error={clientBusinessValid == false}
                      helperText={
                        clientBusinessValid == false && "Invalid Business Name"
                      }
                      id="business"
                      label="Business"
                      name="text"
                    />
                  </FormControl>
                )}
                <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                  Register
                </Button>
                {alert && status == "400" ? <ToastContainer /> : <></>}
                {console.log("alert state", alert)}
                {console.log("status", status)}
                {console.log("status type", typeof status)}
                {alert && status == "200" ? (
                  <>
                    {console.log("inside 200")}
                    <ToastContainer />
                  </>
                ) : (
                  <></>
                )}

                {/* Dialog */}
                <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                  <DialogTitle>Form Validation Failed</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Please fill in all required fields correctly.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default AddClientDialog;
