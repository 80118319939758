import { Box, Container, CssBaseline, Grid, InputAdornment, Paper, Typography } from '@mui/material'
import React, { useRef } from 'react'
import Logo from "./img/logo.jpg";
import { FormControl, TextField, InputLabel, Select, MenuItem, Button, FormControlLabel } from '@mui/material'
import { useState, useEffect, useCallback } from 'react';
import { FRONTEND_API } from "./urls";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import dayjs from 'dayjs';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import PDFDemo from './styles/PDFDemo';
import AddIcon from '@mui/icons-material/Add';
import LabelIcon from '@mui/icons-material/Label';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddClientDialog from './AddClientDialog';
import { BootstrapDialog } from './styles/DialogStyle';
import { ButtonInfoBox, ClientBox, ClinetInfoBox, InfoBox } from './styles/InvoiceStyle';

import { ButtonContainer } from './styles/style';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DateCalendar } from '@mui/x-date-pickers';
import MessageComponent from './MessageComponent';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#343F71',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#FBF1F7',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const currencies = [
    {
        value: '₹',
        label: 'Indian Rupee(INR, ₹)',
    },
    {
        value: '$',
        label: 'US Dollar(USD, $)',
    },
    {
        value: '£',
        label: 'British Pound Sterling(GBP, £)',
    },
    {
        value: 'A$',
        label: 'Australian Dollar(AUD, $)',
    },
];

function generateInvoiceNumber() {
    // Generate a random 4-digit number
    const random4Digits = Math.floor(1000 + Math.random() * 9000);
    const invoiceNumber = `TH-${random4Digits}`;
    return invoiceNumber;
}

function generateOrderId() {
    // Generate a random 4-digit number
    const random4Digits = Math.floor(1000 + Math.random() * 9000);
    const orderID = `OD-${random4Digits}`;
    return orderID;
}

function NewClientInvoice() {

    let params = useParams();

    const [client, setclient] = useState([]);
    const [clientId, setClientId] = useState(0);
    const token = localStorage.getItem("token")
    const [currencyValue, setCurrencyValue] = React.useState(currencies.at(0).value)

    const [taxType, setTaxType] = React.useState('');

    const [selectedTax, setSelectedTax] = useState('');

    const [radioError, setRadioError] = useState(false);

    const [savedTax, setSavedTax] = React.useState('');
    const [saveSubTax, setSaveSubTax] = useState('');

    const [orders, setOrders] = useState([]);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [discountDialog, setDiscountDialogOpen] = useState(false);

    const [dialogValidationOpen, setDialogValidationOpen] = useState(false);
    const [paidAmountDialog, setPaidAmountDialog] = useState(false);

    const [paidAmount, setPaidAmount] = useState(0);

    const [tableData, setTableData] = useState([
        { id: 0, order_id: generateOrderId(), item: '', taxRate: 0, quantity: 1, rate: 0, amount: 0, igst: 0, sgst: 0, cgst: 0, amount: 0, total: 0 },
    ]);

    const today = dayjs();
    const nextDate = today.add(1, 'day');

    const [dueDate, setDueDate] = useState(nextDate);
    const [invoiceDate, setInvoiceDate] = useState(today);

    const [invoiceNumber, setInvoiceNumber] = useState(null);

    const [discount, setDiscount] = useState(0);

    const [alert, setAlert] = useState(false);
    const [status, setStatus] = useState('');
    const [alertContent, setAlertContent] = useState('');

    const componentRef = useRef();

    useEffect(() => {
        generateAndSetInvoiceNumber();
        if (params.userId) {

            fetchOrderData(params.userId);
        } else {
            console.log("no params");
        }
    }, []);

    const fetchOrderData = (userId) => {
        console.log("Tutor ID", userId);
        fetch(FRONTEND_API + "/getStudentOrderHistory/".concat(userId), {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((res) => res.json())
            .then((data) => {
                //do something with data
                console.log("Orders data", data);

                setClientId(data[0].client_id)
                fetchClientForInvoice(data[0].client_id);
                setOrders(data);
                setCurrency(data[0].currency);
                // navigate("/OTMform");
                updateTableDataWithReceivedData(tableData, data);
            })
            .catch((rejected) => {
                console.log(rejected);
            });
    };

    const setCurrency = (currency) => {
        if (currency === 'INR') {
            setCurrencyValue('₹');
        } else if (currency === 'GBP') {
            setCurrencyValue('£')
        } else if (currency === 'USD') {
            setCurrencyValue('$');
        } else {
            setCurrencyValue('A$');
        }
    }

    const fetchClientForInvoice = (userId) => {
        console.log("Tutor ID", userId);
        fetch(FRONTEND_API + "getClientFromClientId/".concat(userId), {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((res) => res.json())
            .then((data) => {
                // do something with data
                console.log("Clients data", data);
                data.map((user) => {
                    setclient(user);
                })
                // navigate("/OTMform");
            })
            .catch((rejected) => {
                console.log(rejected);
            });
    };





    const handleAddRow = () => {
        const newRow = {
            id: tableData.length + 1,
            name: `Row ${tableData.length + 1}`,
            order_id: generateOrderId(),
            description: `Description ${tableData.length + 1}`,
        };
        setTableData([...tableData, newRow]);

        console.log(tableData);
    };


    const handleCloseDialog = () => {
        setDialogValidationOpen(false);
    };

    const handleRemoveRow = (index) => {
        console.log(index);
        const updatedTableData = tableData.filter((row) => row.id !== index);
        setTableData(updatedTableData);
    }

    const handleTaxRadioChange = (event) => {
        //setSelectedRadio(event.target.value);
        console.log(event.target.value);
        setSelectedTax(event.target.value);
    };

    const handleCurrencyChange = (event) => {

        const targetCurrency = event.target.value;
        setCurrencyValue(targetCurrency);
    };


    const handleCloseTax = () => {
        if (savedTax == null) {
            setDialogOpen(false);
            setTaxType('');
            setSelectedTax('');
        } else {
            setDialogOpen(false);
        }
    };

    const handlePaidDialog = () => {
        setPaidAmountDialog(true);
    }
    const handleClosePaidDialog = () => {
        console.log(discountDialog);
        setPaidAmountDialog(false);


    };

    const handleSavePaidAmt = () => {
        if (paidAmountDialog) {
            setPaidAmountDialog(false);
        }
    };

    const handlePaidAmountChange = (event) => {
        setPaidAmount(event.target.value);
    }


    const handleDiscount = () => {
        setDiscountDialogOpen(true)
    };


    const handleCloseDiscount = () => {
        console.log(discountDialog);
        setDiscountDialogOpen(false);
        setDiscount(0);

    };

    const handleSaveDiscount = () => {
        if (discountDialog) {
            setDiscountDialogOpen(false);
        }
    };

    const handleDiscountChange = (event) => {
        const { name, value } = event.target;
        setDiscount(value);
    };

    const handleTaxUpdate = () => {
        setDialogOpen(true);
    };

    const handleTaxTypeChange = (event) => {
        const { name, value } = event.target;
        if (savedTax != null)
            setTaxType(value);
        else
            setTaxType(savedTax);

    };

    const handleSaveTax = () => {
        if (taxType == 'gst') {
            if (selectedTax == '') {
                setRadioError(true);
            } else {
                setRadioError(false)
                setSavedTax(taxType)
                setSaveSubTax(selectedTax)

                setDialogOpen(false);
            }
        } else if (taxType == 'none') {
            setSavedTax('')
            setDialogOpen(false);
        } else {
            setSavedTax(taxType);
            setDialogOpen(false);
        }
    };

    const [openInvoiceDatePicker, setOpenInvoiceDatePicker] = useState(false);
    const [openDueDatePicker, setOpenDueDatePicker] = useState(false);

    const invoiceButtonRef = useRef(null);
    const dueButtonRef = useRef(null);

    const handleInvoiceButtonClick = () => {
        if (openInvoiceDatePicker) {
            setOpenInvoiceDatePicker(false);
        } else {
            setOpenInvoiceDatePicker(true);
        }
    }

    const handleDueButtonClick = () => {
        if (openDueDatePicker) {
            setOpenDueDatePicker(false);
        } else {
            setOpenDueDatePicker(true);
        }
    }

    const handleInvoiceDate = (date) => {
        setInvoiceDate(date);
        setOpenInvoiceDatePicker(false);
    }

    const handleDueDate = (date) => {
        setDueDate(date);
        setOpenDueDatePicker(false);
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    /*  const handlePrint = () => {
       window.print();
     }; */

    const [selectedFile, setSelectedFile] = useState([]);

    const handleFileChange = (event) => {
        const files = event.target.files;
        console.log("file length", files.length);
        // Check if any file is selected
        if (files && files.length > 0) {
            const validFiles = [];

            // Iterate through each file
            for (let i = 0; i < files.length; i++) {
                const fileType = files[i].name.split('.').pop().toLowerCase();

                // Check file type
                if (
                    fileType === 'doc' ||
                    fileType === 'pdf' ||
                    fileType === 'jpeg' ||
                    fileType === 'jpg' ||
                    fileType === 'png'
                ) {
                    // Check file size (2MB limit)
                    if (files[i].size <= 2 * 1024 * 1024) {
                        validFiles.push(files[i]);
                    } else {
                        alert('File size exceeds 2MB limit for file: ' + files[i].name);
                    }
                } else {
                    alert('Invalid file type for file: ' + files[i].name);
                }
            }
            console.log(validFiles);
            setSelectedFile(validFiles);
        }
    };


    const [userClientType, setUserClientType] = useState('');
    const [userClient, setUserClient] = React.useState('');

    const handleChangeClient = (event) => {
        const newValue = event.target.value;
        setUserClient(newValue);
        console.log("set user client", event.target.value);
        setClientId(newValue);
        //getOrdersOfClient(newValue);
    };

    const handleRadioChange = (event) => {
        //setSelectedRadio(event.target.value);
        console.log(event.target.value);
        setUserClientType(event.target.value);
        fetchClientInitial(event.target.value);
    };

    const fetchClientInitial = (type) => {

        if (type === 'student') {
            fetch(FRONTEND_API + "getstudentclientdata", {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
                .then((res) => res.json())
                .then((data) => {
                    // do something with data
                    console.log(data);
                    setclient(data);
                })
                .catch((rejected) => {
                    console.log(rejected);
                }
                );
        } else {
            fetch(FRONTEND_API + "getvendoreclientdata", {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
                .then((res) => res.json())
                .then((data) => {
                    // do something with data
                    console.log(data);
                    setclient(data);
                })
                .catch((rejected) => {
                    console.log(rejected);
                }
                );
        }
    };

    const [clientInvoice, setClientInvoice] = useState(null);
    const [downloadPdf, setDownloadPdf] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');
    const [severity, setSeverity] = useState(null);

    const insertInvoice = async () => {
        console.log(clientId);
        const pdf = new jsPDF('p', 'mm', 'a4');
        let pdfBlob;

        await html2canvas(componentRef.current).then(async (canvas) => {
            let imgData, quality = 1.0;

            do {
                // Convert canvas to image data URL with the current quality
                imgData = canvas.toDataURL('image/jpeg', quality);

                // Add the image to the PDF
                pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297); // Adjust width and height as needed

                // Output PDF blob
                pdfBlob = pdf.output('blob');

                // Check the size of the PDF blob
                if (pdfBlob.size > 1024 * 1024 && quality > 0.1) {
                    // If the size is still greater than 1MB, reduce quality and retry
                    quality -= 0.1;
                    pdf.deletePage(); // Remove the added page
                } else {
                    // Break the loop if the size is within the limit or quality is too low
                    break;
                }
            } while (true);
        });

        //const pdfBlob = pdf.output('blob');
        // const pdfFile = new File([pdfBlob], 'invoice.pdf', { type: 'application/pdf' });
        console.log(pdfBlob)

        const data = tableData;
        console.log("Client info", client);
        if (client.length == 0) {
            console.log("Client info", 'null');
            setDialogValidationOpen(true);
            setErrorMessage("Please Select your Client");
            setSeverity(false);
            return
        } else if (invoiceDate == null || dueDate == null) {
            setDialogValidationOpen(true);
            setErrorMessage("You have not selected invoice date or due date.");
            setSeverity(false);
            return
        } else if (getTotal() == 0) {
            console.log("Total", 'null');
            setDialogValidationOpen(true);
            setErrorMessage("You have not added any item in the invoice.");
            setSeverity(false);
            return
        }

        var saveCurrency = '';

        if (currencyValue == '$') {
            saveCurrency = 'USD'
        } else if (currencyValue == '$AUD') {
            saveCurrency = 'AUD'
        } else if (currencyValue == '₹') {
            saveCurrency = 'INR'
        } else if (currencyValue == '£') {
            saveCurrency = 'GBP'
        }

        console.log(saveCurrency);

        const formData = new FormData();
        formData.append('data', JSON.stringify({
            data: data,
            invoiceNumber: invoiceNumber,
            invoiceDate: invoiceDate,
            dueDate: dueDate,
            taxType: savedTax,
            discount: getDiscount(),
            diskPercent: discount,
            discountType: discountType,
            currency: saveCurrency,
            total: getTotal(),
            totalAmount: getTotalAmount(),
            subTax: saveSubTax,
            paidAmount: paidAmount,
            taxRate: data.taxRate
        }));

        console.log("Files to be upload ", selectedFile);

        for (let i = 0; i < selectedFile.length; i++) {
            formData.append('document', selectedFile[i]);
        }
        // formData.append('invoicepdf', pdfFile);
        console.log(formData);

        var requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: formData,
        };
        console.log(clientId);
        let alertCondition = '';
        fetch(FRONTEND_API + 'saveInvoice/'.concat(clientId), requestOptions)
            .then((response) => {
                if (response.status == 200) {
                    setStatus("200")
                    alertCondition = 200;
                    return response.json();
                } else {
                    setStatus(response.status)
                    alertCondition = response.status
                    return response.json();
                }
            })
            .then((data) => {
                /* console.log(data);
                setAlertContent();
                setAlert(true);
                setClientInvoice(data);
                 */

                if (alertCondition == '200') {
                    setTimeout(() => {
                        setDownloadPdf(true);
                    }, 2000);
                    console.log(data);
                    setClientInvoice(data);
                } else {
                    setDialogValidationOpen(true);
                    setErrorMessage(data.message);
                    setSeverity(false);
                }




            })
            .catch((error) => {
                console.error('Error:', error);
            });


    };

    const generateAndSetInvoiceNumber = () => {
        const newInvoiceNumber = generateInvoiceNumber();
        setInvoiceNumber(newInvoiceNumber);
    };

    //amount Conversion method
    const convertValue = (amount) => {

        if (currencyValue == "$") {
            return amount * 83.10;
        } else if (currencyValue == "A$") {
            return amount * 54.81;
        } else {
            return amount * 105.84;
        }

    }

    const updateTableDataWithReceivedData = (tableData, receivedData) => {
        // Assuming the receivedData array contains an object with the data you want to add

        // Make a copy of the existing tableData array
        const updatedTableData = [...tableData];

        for (let i = 0; i < receivedData.length; i++) {
            const receivedItem = receivedData[i];
            //const conv_amount = convertValue(receivedItem.order_budget, receivedItem.currency);

            updatedTableData[i] = {
                ...updatedTableData[i],
                id: i,
                order_id: receivedItem.id,
                amount: receivedItem.order_budget,
                item: receivedItem.task,
                total: receivedItem.order_budget,
                quantity: 1,
                rate: receivedItem.order_budget,
            };
        }
        console.log(updatedTableData);
        // Set the updated tableData to the state
        setTableData(updatedTableData);
    };

    const handleInputChange = (event, field, id) => {
        const updatedTableData = tableData.map((row) => {
            if (row.id === id) {
                const newValue = event.target.value;
                let taxRate = parseFloat(row.taxRate) || 0;
                let quantity = parseFloat(row.quantity) || 0;
                let amount = parseFloat(row.amount) || 0;
                let rate = parseFloat(row.rate) || 0;

                if (field === 'quantity') {
                    quantity = parseFloat(newValue) || 0;
                } else if (field === 'rate') {
                    rate = parseFloat(newValue) || 0;
                } else if (field === 'taxRate') {
                    taxRate = parseFloat(newValue) || 0;
                }

                let igst = 0; // Initialize igst, cgst, sgst to 0
                let cgst = 0;
                let sgst = 0;
                let vat = 0;
                let total = 0;
                console.log(savedTax);
                amount = rate * quantity;
                if (savedTax === 'gst') {
                    if (saveSubTax === 'igst') {
                        igst = (taxRate / 100) * (amount);
                    } else if (saveSubTax === 'gst') {
                        cgst = (taxRate / 200) * (amount);
                        sgst = (taxRate / 200) * (amount);
                    }
                    total = amount + igst + cgst + sgst;
                } else if (savedTax === 'vat') {
                    console.log(taxRate / 100);
                    vat = (taxRate / 100) * (amount);
                    console.log(vat);
                    total = amount + vat;
                } else {
                    total = amount;
                }

                //igst = (taxRate / 100) * (amount);

                return { ...row, [field]: newValue, amount, igst, cgst, sgst, vat, total };
            }
            return row;
        });
        setTableData(updatedTableData);

    };

    const getTotal = () => {
        let total = 0;
        tableData.forEach((row) => {
            total += row.total;
        })
        if (discount != 0) {
            return total - getDiscount();
        } else if (paidAmount != 0 || paidAmount != null) {
            return total - paidAmount;
        } else {
            return total;
        }

    }

    //Logic to implement discount
    const getDiscount = () => {
        let total = 0;
        tableData.forEach((row) => {
            total += row.total;
        })
        if (discountType === 10) {
            const discountAmount = (discount / 100) * total;
            return discountAmount;
        } else {
            const discountAmount = discount * 1.0;
            console.log(discountAmount);
            return discountAmount;
        }

    }

    const getTotalAmount = () => {
        let total = 0;
        tableData.forEach((row) => {
            total += row.amount;
        })
        console.log(getDiscount())
        return total;
    }

    const getIgst = () => {
        let total = 0;
        if (savedTax == 'gst' && saveSubTax == 'igst') {
            tableData.forEach((row) => {
                total += row.igst;
            })
        }
        else if (savedTax == 'vat') {
            tableData.forEach((row) => {
                total += row.vat;
            })
        }
        return total;
    }

    const getSgst = () => {
        let total = 0;
        tableData.forEach((row) => {
            total += row.sgst
        })
        return total;
    }

    const getCgst = () => {
        let total = 0;
        tableData.forEach((row) => {
            total += row.cgst
        })
        return total;
    }

    function getFile(blob) {
        console.log(blob);
        const formData = new FormData();
        formData.append('invoicepdf', blob);
        console.log(formData);

        var requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: formData,
        };

        let respStatus = 0;

        fetch(FRONTEND_API + 'uploadInvoice/'.concat(invoiceNumber), requestOptions)
            .then((response) => {
                if (response.status == 200) {
                    setStatus("200")
                    respStatus = 200
                    return response.json();
                } else {
                    setStatus(response.status)
                    respStatus = response.status
                    return response.json();
                }
            })
            .then((data) => {
                console.log(data);
                if (respStatus == 200) {
                    setDialogValidationOpen(true);
                    setErrorMessage(data.message);
                    setSeverity(true);
                    setDownloadPdf(false);
                } else {
                    setDialogValidationOpen(true);
                    setErrorMessage(data.message);
                    setSeverity(false);
                    setDownloadPdf(false);
                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const [discountFieldVisible, setDiscountFieldVisible] = useState(false);
    const [addDiscountButton, setaddDiscountButton] = useState(true);

    const showDiscountFields = () => {
        if (!discountFieldVisible) {
            setDiscountFieldVisible(true);
            setaddDiscountButton(false);
        }
    }
    const [discountType, setDiscountType] = React.useState('');

    const handleDisChangeValue = (event) => {
        setDiscountType(event.target.value);
    }


    const getClientsDetails = () => {
        var selectedClient = null;
        if (client != null) {
            selectedClient = client.filter(filteredClients => filteredClients.id == userClient)
            return selectedClient;
        } else {
            return null;
        }
    }
    const [openClientDialog, setOpenClientDialog] = useState(false);
    const handleCreateClient = () => {
        setOpenClientDialog(true);
    }

    const handleCloseClientDialog = () => {
        setOpenClientDialog(false);
    }

    const epsilon = 0.0001;

    return (
        <Container sx={{ bgcolor: "#FBF1F7" }}>
            <Box sx={{ display: 'flex', }}>
                <CssBaseline />
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3}>
                        {/* Chart */}
                        <Grid item xs={12}>
                            <Paper sx={{ m: 5, p: 5, display: 'flex', flexDirection: 'column', border: '1px solid #C7A1BD' }} >
                                <div ref={componentRef}>
                                    <Typography variant="h4" sx={{ display: 'flex', justifyContent: 'center', mb: '20px' }}>
                                        Invoice
                                    </Typography>

                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <div>
                                                        <strong>Invoice No:</strong>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div>
                                                        <p>{invoiceNumber}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div>
                                                        <strong>Date:</strong>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div>
                                                        <ButtonContainer
                                                            role="button"
                                                            onClick={handleInvoiceButtonClick}
                                                            size='small'
                                                            ref={invoiceButtonRef}
                                                        >
                                                            <span style={{ marginLeft: '10px' }}>{invoiceDate.format('DD-MM-YYYY')}</span>
                                                            <IconButton sx={{ display: 'flex', alignItems: 'end', alignContent: 'end' }}>
                                                                <CalendarTodayIcon />
                                                            </IconButton>
                                                        </ButtonContainer>

                                                        {openInvoiceDatePicker && (
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    zIndex: 9999,
                                                                    top: invoiceButtonRef.current.getBoundingClientRect().bottom + window.scrollY + 5 + 'px', // Added a small offset
                                                                    left: invoiceButtonRef.current.getBoundingClientRect().left + 'px',
                                                                    backgroundColor: '#fff',
                                                                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                                                }}
                                                            >
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DateCalendar
                                                                        value={invoiceDate}
                                                                        onChange={(newValue) => handleInvoiceDate(newValue)}
                                                                        renderInput={() => null} // Hide the input inside the calendar
                                                                        PopperProps={{
                                                                            style: { zIndex: 9999, backgroundColor: '#fff', position: 'fixed' },
                                                                        }}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                        )}

                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div>
                                                        <strong>Add Due Date:</strong>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div>
                                                        <ButtonContainer
                                                            sx={{ m: 1 }}
                                                            role="button"
                                                            onClick={handleDueButtonClick}
                                                            size='small'
                                                            ref={dueButtonRef}
                                                        >
                                                            <span style={{ marginLeft: '10px' }}>{dueDate.format('DD-MM-YYYY')}</span>
                                                            <IconButton sx={{ display: 'flex', alignItems: 'end', alignContent: 'end' }}>
                                                                <CalendarTodayIcon />
                                                            </IconButton>
                                                        </ButtonContainer>

                                                        {openDueDatePicker && (
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    zIndex: 99,
                                                                    top: dueButtonRef.current.offsetTop + dueButtonRef.current.offsetHeight + 'px',
                                                                    left: dueButtonRef.current.offsetLeft + 'px',
                                                                    backgroundColor: '#fff',
                                                                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                                                }}>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DateCalendar
                                                                        value={dueDate}
                                                                        onChange={(newValue) => handleDueDate(newValue)}
                                                                        renderInput={() => null} // Hide the input inside the calendar
                                                                        PopperProps={{
                                                                            style: { zIndex: 99, backgroundColor: '#fff' }, // Adjust z-index as needed
                                                                        }}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                        )}
                                                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                value={dueDate} // Set the value prop to display the selected date
                                onChange={handleDueDate} // Capture the selected date
                                renderInput={(params) => <TextField {...params} label="Select Date" variant="outlined" />}
                              />
                            </LocalizationProvider> */}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={Logo} alt='BigCo Inc. logo' id='invoicelogo' />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3} marginTop="10px">
                                        <Grid item xs={6}>
                                            <ClientBox>
                                                <Box sx={{ paddingBottom: 2 }}>
                                                    <strong>Billed By-</strong>
                                                    <ClinetInfoBox>
                                                        <span id='tutorshivetext'> TutorsHive Pvt. Ltd. </span>
                                                        <br />
                                                        Regd. office: 88A, Nancy Residency, First Floor, <br />
                                                        Sindhu Nagar, Scheme No. 17, Murlipura, Jaipur, jaipur,
                                                        <br />
                                                        Email: info@webz.com.pl
                                                    </ClinetInfoBox>

                                                </Box>
                                            </ClientBox>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ClientBox>
                                                <strong>Billed To-</strong>
                                                {params.userId ? (
                                                    <div>
                                                        {client != null && (
                                                            <ClinetInfoBox>
                                                                <p>Name: {client.name}</p>
                                                                <p>Email: {client.email}</p>
                                                                <p>Contact: {client.contact}</p>
                                                            </ClinetInfoBox>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            value={userClientType}
                                                            onChange={handleRadioChange}
                                                        >
                                                            <FormControlLabel value="student" control={<Radio />} label="Student" />
                                                            <FormControlLabel value="vendor" control={<Radio />} label="Vendor" />
                                                        </RadioGroup>

                                                        {userClientType != '' && (
                                                            <FormControl fullWidth>

                                                                <Select
                                                                    fullWidth
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={userClient}

                                                                    variant='outlined'
                                                                    onChange={handleChangeClient}
                                                                    size='small'
                                                                >
                                                                    {client.map((data) => (
                                                                        <MenuItem value={data.id}>{data.name}</MenuItem>

                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                        {getClientsDetails() != null && (
                                                            <ClinetInfoBox>
                                                                {getClientsDetails().map((selectedClient) => (
                                                                    <div key={selectedClient.id}>
                                                                        <p>Name: {selectedClient.name}</p>
                                                                        <p>Email: {selectedClient.email}</p>
                                                                        <p>Contact: {selectedClient.contact}</p>
                                                                    </div>
                                                                ))}

                                                                {userClientType == '' && (
                                                                    <ButtonInfoBox>
                                                                        <InfoBox>
                                                                            Select Client/Business from list
                                                                        </InfoBox>
                                                                        <InfoBox>
                                                                            OR
                                                                        </InfoBox>
                                                                        <Button
                                                                            startIcon={<AddIcon />}
                                                                            variant='contained'
                                                                            onClick={handleCreateClient}>
                                                                            Add new Client
                                                                        </Button>
                                                                    </ButtonInfoBox>
                                                                )}
                                                            </ClinetInfoBox>
                                                        )}


                                                    </div>
                                                )}

                                            </ClientBox>
                                        </Grid>
                                    </Grid>
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                        <Button variant="outlined" sx={{ mt: 3, mb: 2, marginRight: 2 }}
                                            onClick={handleTaxUpdate}>
                                            {savedTax != '' ? 'Configure Tax' : 'Add Tax'}
                                        </Button>
                                        <InputLabel id="demo-simple-select-label" sx={{ marginRight: 2 }}>Currency</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={currencyValue}

                                            onChange={handleCurrencyChange}
                                            error={currencyValue === ''}
                                            helperText={currencyValue === '' && 'Select Currency'}
                                            fullWidth
                                            sx={{ width: '200px' }}
                                        >
                                            {currencies.map((data) => (

                                                <MenuItem value={data.value}>{data.label}</MenuItem>

                                            ))}
                                        </Select>
                                    </div>

                                    <Box>
                                        <TableContainer component={Paper} sx={{
                                            marginBottom: 6,
                                            marginRight: 2,
                                            mt: 3
                                        }}
                                            aria-label="customized table" >

                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead fullWidth>
                                                    <StyledTableRow>
                                                        <StyledTableCell>Item</StyledTableCell>
                                                        {savedTax && (
                                                            <StyledTableCell >{savedTax == 'gst' ? 'GST Rate' : 'VAT Rate'}</StyledTableCell>
                                                        )}
                                                        <StyledTableCell>Quantity</StyledTableCell>
                                                        <StyledTableCell >Rate</StyledTableCell>
                                                        {/* */}

                                                        {savedTax == 'gst' && saveSubTax == 'gst' && (

                                                            <StyledTableCell >Amount</StyledTableCell>


                                                        )}
                                                        {savedTax == 'gst' && saveSubTax == 'igst' && (

                                                            <StyledTableCell >Amount</StyledTableCell>


                                                        )}
                                                        {savedTax == 'gst' && saveSubTax == 'igst' && (
                                                            <StyledTableCell >IGST</StyledTableCell>
                                                        )}
                                                        {savedTax == 'gst' && saveSubTax == 'gst' && (

                                                            <StyledTableCell >CGST</StyledTableCell>


                                                        )}
                                                        {savedTax == 'gst' && saveSubTax == 'gst' && (

                                                            <StyledTableCell >SGST</StyledTableCell>

                                                        )}
                                                        {savedTax == 'vat' && (

                                                            <StyledTableCell >VAT</StyledTableCell>

                                                        )}

                                                        <StyledTableCell >Total</StyledTableCell>
                                                        <StyledTableCell ></StyledTableCell>
                                                    </StyledTableRow>
                                                </TableHead>


                                                {tableData.length > 0 && tableData.map((data, index) => (
                                                    <TableBody key={data.id}>
                                                        <StyledTableCell>
                                                            <div>{data.order_id}</div>
                                                            <TextField
                                                                variant="standard"
                                                                value={data.item}
                                                                onChange={(event) => handleInputChange(event, 'item', data.id)}
                                                            />
                                                        </StyledTableCell>
                                                        {savedTax && (
                                                            <StyledTableCell>
                                                                <div>
                                                                    <TextField

                                                                        variant="standard"
                                                                        value={data.taxRate}
                                                                        onChange={(event) => handleInputChange(event, 'taxRate', data.id)}
                                                                        InputProps={{
                                                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                                        }}
                                                                    />

                                                                </div>
                                                            </StyledTableCell>
                                                        )}
                                                        <StyledTableCell>
                                                            <TextField id="standard-basic"

                                                                variant="standard"
                                                                value={data.quantity}
                                                                onChange={(event) => handleInputChange(event, 'quantity', data.id)}
                                                            />
                                                        </StyledTableCell>

                                                        <StyledTableCell>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>
                                                                <TextField
                                                                    id="standard-basic"

                                                                    variant="standard"
                                                                    value={data.rate}
                                                                    onChange={(event) => handleInputChange(event, 'rate', data.id)}
                                                                />
                                                            </div>
                                                        </StyledTableCell>

                                                        {savedTax == 'gst' && saveSubTax == 'igst' && (
                                                            <StyledTableCell>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>

                                                                    <TextField
                                                                        id="standard-basic"
                                                                        disabled
                                                                        variant="standard"
                                                                        value={data.amount}
                                                                        onChange={(event) => handleInputChange(event, 'amount', data.id)}
                                                                    />

                                                                </div>
                                                            </StyledTableCell>
                                                        )}

                                                        {savedTax == 'gst' && saveSubTax == 'igst' && (
                                                            <div>


                                                                <StyledTableCell >
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>

                                                                        <TextField
                                                                            id="standard-basic"
                                                                            disabled
                                                                            variant="standard"
                                                                            value={data.igst != null ? data.igst.toFixed(2) : 0}
                                                                            onChange={(event) => handleInputChange(event, 'igst', data.id)}

                                                                        />

                                                                    </div>
                                                                </StyledTableCell>
                                                            </div>
                                                        )}
                                                        {savedTax == 'gst' && saveSubTax == 'gst' && (
                                                            <StyledTableCell>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>

                                                                    <TextField
                                                                        id="standard-basic"
                                                                        disabled
                                                                        variant="standard"
                                                                        value={data.amount}
                                                                        onChange={(event) => handleInputChange(event, 'amount', data.id)}
                                                                    />

                                                                </div>
                                                            </StyledTableCell>
                                                        )}
                                                        {savedTax == 'gst' && saveSubTax == 'gst' && (
                                                            <div>


                                                                <StyledTableCell >
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>
                                                                        <TextField
                                                                            disabled
                                                                            id="standard-basic"

                                                                            variant="standard"
                                                                            value={data.cgst != null ? data.cgst.toFixed(2) : 0}
                                                                            onChange={(event) => handleInputChange(event, 'cgst', data.id)}

                                                                        />
                                                                    </div>
                                                                </StyledTableCell>
                                                            </div>

                                                        )}
                                                        {savedTax == 'gst' && saveSubTax == 'gst' && (
                                                            <StyledTableCell >
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>

                                                                    <TextField
                                                                        disabled
                                                                        id="standard-basic"
                                                                        variant="standard"
                                                                        value={data.sgst != null ? data.sgst.toFixed(2) : 0}
                                                                        onChange={(event) => handleInputChange(event, 'sgst', data.id)}

                                                                    />
                                                                </div>
                                                            </StyledTableCell>
                                                        )}

                                                        {savedTax == 'vat' && (

                                                            <StyledTableCell >
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>

                                                                    <TextField
                                                                        disabled
                                                                        id="standard-basic"
                                                                        variant="standard"
                                                                        value={data.vat != null ? data.vat.toFixed(2) : 0}
                                                                        onChange={(event) => handleInputChange(event, 'vat', data.id)}

                                                                    />
                                                                </div>
                                                            </StyledTableCell>

                                                        )}

                                                        <StyledTableCell>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>

                                                                {data.total != null ? data.total.toFixed(2) : 0}
                                                            </div>
                                                        </StyledTableCell>
                                                        <StyledTableCell> <CloseIcon onClick={() => handleRemoveRow(data.id)} /></StyledTableCell>
                                                    </TableBody>
                                                ))}
                                            </Table>
                                        </TableContainer>

                                        {tableData.length >= 1 && (
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                <Grid container spacing={2}>

                                                    {savedTax !== "" && (
                                                        <>
                                                            <Grid item xs={9}>
                                                                <Typography align="right" variant="subtitle1">
                                                                    Amount:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography align="right" variant="subtitle1">
                                                                    <span style={{ marginRight: '2px', marginTop: '0px' }}>{currencyValue}</span>
                                                                    {isNaN(getTotalAmount()) ? 0 : getTotalAmount().toFixed(2)}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    )}


                                                    {savedTax === 'gst' && saveSubTax === 'gst' && (
                                                        <>
                                                            <Grid item xs={9}>
                                                                <Typography align="right" variant="subtitle1">
                                                                    SGST:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography align="right" variant="subtitle1">
                                                                    {isNaN(getSgst()) ? 0 : getSgst().toFixed(2)}
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={9}>
                                                                <Typography align="right" variant="subtitle1">
                                                                    CGST:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography align="right" variant="subtitle1">
                                                                    {isNaN(getCgst()) ? 0 : getCgst().toFixed(2)}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    )}

                                                    {savedTax === 'gst' && saveSubTax === 'igst' && (
                                                        <>
                                                            <Grid item xs={9}>
                                                                <Typography align="right" variant="subtitle1">
                                                                    IGST:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography align="right" variant="subtitle1">
                                                                    {isNaN(getIgst()) ? 0 : getIgst().toFixed(2)}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    )}

                                                    {savedTax === 'vat' && (
                                                        <>
                                                            <Grid item xs={9}>
                                                                <Typography align="right" variant="subtitle1">
                                                                    VAT:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography align="right" variant="subtitle1">
                                                                    (+){isNaN(getIgst()) ? 0 : getIgst().toFixed(2)}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    )}
                                                    {paidAmount != 0 && paidAmount != null && (
                                                        <>
                                                            <Grid item xs={9}>
                                                                <div>
                                                                    <Typography align="right" variant="subtitle1">
                                                                        Paid Amount:
                                                                        <EditIcon onClick={handlePaidDialog} sx={{ marginLeft: '5px' }} fontSize='small' />
                                                                    </Typography>

                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography align="right" variant="subtitle1">
                                                                    <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>
                                                                    (-) {paidAmount}
                                                                    {/* <TextField
                                sx={{width: '30px'}}
                                variant="standard"
                                value={paidAmount}
                                onChange={(event) => handlePaidAmountChange(event)}
                              /> */}
                                                                </Typography>
                                                            </Grid>

                                                        </>

                                                    )}


                                                    {Math.abs(discount) > epsilon ? (
                                                        <>
                                                            <Grid item xs={9}>

                                                                <Typography align="right" variant="subtitle1">
                                                                    Discount({discount}{discountType == 10 ? "%" : currencyValue}):
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography align="right" variant="subtitle1">
                                                                    (-){isNaN(getDiscount()) ? 0 : getDiscount().toFixed(2)}
                                                                </Typography>
                                                            </Grid>

                                                        </>

                                                    ) : (<></>)}


                                                    <Grid item xs={9}>
                                                        <Typography align="right" variant="subtitle1" fontWeight="bold">
                                                            Total:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography align="right" variant="subtitle1" fontWeight="bold">
                                                            <span >{currencyValue}</span>
                                                            {isNaN(getTotal()) ? 0 : getTotal().toFixed(2)}
                                                        </Typography>
                                                    </Grid>


                                                </Grid>

                                            </Box>
                                        )}


                                    </Box>
                                </div>
                                {currencyValue != "₹" && (
                                    <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <div style={{ width: '350px', backgroundColor: '#E9D8E4', padding: '10px' }}>
                                            <i>The exchange rate is for indicative purpose only</i>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                                <span>Amount total in your business's currency:</span>
                                                <span style={{ fontWeight: 'bold' }}>₹{convertValue(getTotal())}</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div style={{ marginTop: '10px', display: "flex", alignContent: "end", alignItems: "end" }}>
                                    {addDiscountButton && (

                                        <div style={{ display: "flex", alignContent: "end", alignItems: "end" }}>

                                            <span><LabelIcon style={{ transform: 'rotate(240deg)' }} /></span>

                                            <div role='button' onClick={showDiscountFields}>
                                                <span style={{ marginLeft: '5px' }}>Add Discounts/Additional Charges</span>
                                            </div>

                                        </div>
                                    )}


                                    {discountFieldVisible && (
                                        <div>
                                            <div style={{ marginLeft: '15px', display: "flex", alignContent: "flex-end", alignItems: "end" }} >
                                                <span > <AddBoxIcon /> </span>

                                                <div role='button' onClick={handleDiscount}>
                                                    <span>Give Discount on Total Amount</span>
                                                </div>

                                            </div>


                                            <div style={{ marginLeft: '15px', display: "flex", alignContent: "flex-end", alignItems: "end" }} >

                                                <span > <AddBoxIcon /> </span>
                                                <div role='button' onClick={handlePaidDialog}>
                                                    <span >Amount Paid</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <Button variant='outlined' sx={{ mt: 3, width: '200px' }} onClick={handleAddRow}>
                                    Add More Items
                                </Button>

                                <div style={{ marginTop: '15px' }}>
                                    <input type="file" multiple accept=".doc, .pdf, .jpeg, .jpg, .png" onChange={handleFileChange} />
                                </div>

                                {downloadPdf && (
                                    <PDFDownloadLink document={<PDFDemo invoice={clientInvoice} />} fileName="example.pdf">
                                        {({ blob, url, loading, error }) => {
                                            if (!loading && blob) {
                                                // Use the blob as needed in your application logic
                                                console.log('PDF Blob:', blob);
                                                getFile(blob);
                                                // You can send the blob to the backend here or perform any other action
                                            }

                                            return null; // This will prevent rendering any visible content
                                        }}
                                    </PDFDownloadLink>
                                )}
                                <Button variant="outlined" type='submit' sx={{ mt: 3, width: '200px' }} onClick={() => insertInvoice()}>
                                    Save Invoice
                                </Button>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>

                <AddClientDialog open={openClientDialog} handleClose={handleCloseClientDialog} />

            </Box>
            <BootstrapDialog fullWidth open={discountDialog} sx={{

            }}>
                <DialogTitle>
                    <strong>Add Discount</strong>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDiscount}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent dividers fullWidth sx={{ padding: 3 }}>
                    <InputLabel id="demo-simple-select-label" sx={{ mb: 1 }}>Enter Discount</InputLabel>
                    <div>
                        <TextField
                            id="standard-basic"
                            variant="standard"
                            value={discount}
                            onChange={(event) => handleDiscountChange(event)}
                        /*  InputProps={{
                         endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }} */

                        />
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={discountType}
                            onChange={handleDisChangeValue}
                        >
                            <MenuItem value={10}>%</MenuItem>
                            <MenuItem value={20}>{currencyValue}</MenuItem>
                        </Select>

                    </div>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseDiscount} color="primary">
                        Close
                    </Button>
                    <Button onClick={handleSaveDiscount} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </BootstrapDialog>

            <BootstrapDialog fullWidth open={dialogOpen} sx={{

            }}>
                <DialogTitle>
                    <strong>Configure Tax</strong>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseTax}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers fullWidth sx={{ padding: 3 }}>
                    <InputLabel id="demo-simple-select-label" sx={{ mb: 1 }}>Select Tax Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={taxType}
                        placeholder='Tax'
                        onChange={handleTaxTypeChange}
                        fullWidth
                        sx={{ mb: 1 }}
                    >
                        <MenuItem value={'none'}>None</MenuItem>
                        <MenuItem value={'gst'}>GST (India)</MenuItem>
                        <MenuItem value={'vat'}>VAT</MenuItem>

                    </Select>

                    {taxType == 'gst' && (
                        <Box sx={{ marginTop: 3 }}>
                            <strong>GST Type</strong>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={selectedTax}
                                onChange={handleTaxRadioChange}
                            >

                                <FormControlLabel value="igst" control={<Radio />} label="IGST" />

                                <FormControlLabel value="gst" control={<Radio />} label="CGST & SGST" />
                            </RadioGroup>
                            {radioError && <p style={{ color: 'red' }}>Please select a tax option.</p>}
                        </Box>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseTax} color="primary">
                        Close
                    </Button>
                    <Button onClick={handleSaveTax} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </BootstrapDialog>
            {dialogValidationOpen && (
                <MessageComponent message={errorMessage} severity={severity} close={handleCloseDialog} />

            )}

            <BootstrapDialog fullWidth open={paidAmountDialog}>
                <DialogTitle>
                    <strong>Edit Paid Amount</strong>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClosePaidDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent dividers fullWidth sx={{ padding: 3 }}>
                    <InputLabel id="demo-simple-select-label" sx={{ mb: 1 }}>Enter Paid amount</InputLabel>
                    <div>
                        <TextField
                            id="standard-basic"
                            variant="standard"
                            value={paidAmount}
                            onChange={(event) => handlePaidAmountChange(event)}
                        /*  InputProps={{
                           endAdornment: <InputAdornment position="end">%</InputAdornment>,
                         }} */

                        />

                    </div>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClosePaidDialog} color="primary">
                        Close
                    </Button>
                    <Button onClick={handleSavePaidAmt} color="primary">
                        Save
                    </Button>
                </DialogActions>

            </BootstrapDialog>

            {/*   <Dialog open={dialogValidationOpen} onClose={handleCloseDialog}>
        <DialogTitle>Form Validation Failed</DialogTitle>
        <DialogContent>
          <DialogContentText>Please fill in all required fields correctly.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog> */}
        </Container>
    )
}

export default NewClientInvoice