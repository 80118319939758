import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Button, Grid, Menu, MenuItem, Paper } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import MoreIcon from "@mui/icons-material/MoreVert";
import { FRONTEND_API } from "./urls";
import SideItems from "./SideItems";
import logo2 from "./img/logo2 2.png";
const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: "black !important", // Change this to your desired link color
  textDecoration: "none",
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const NavBarMain = ({ onDrawerOpen, onDrawerClose }) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hamburgerMenuOn, setHamburgerMenuOn] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const email = localStorage.getItem("email");

  /*   const handleDrawerOpen = () => {
    if (!open)
      setOpen(true);
    else
      setOpen(false);
  }; */

  var isClickedOpen = Boolean(null);

  const handleDrawerOpen = () => {
    console.log("Drawer open", onDrawerOpen);
    if (!open) {
      isClickedOpen = true;
      console.log("is clicked open", isClickedOpen);
      onDrawerOpen();
    } // Call the onDrawerOpen function passed from the parent
    else {
      console.log(onDrawerClose, isClickedOpen);
      isClickedOpen = false;
      onDrawerClose();
    }
    setOpen(!open);
    console.log(open); // Toggle the 'open' state
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleLogout = async () => {
    // if used in more components, this should be in context
    // axios to /logout endpoint
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    fetch(FRONTEND_API + "logout")
      .then(() => {
        window.location.href = "/"; // Redirect to home page after logout
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  };

  const handleMouseEnter = () => {
    if (!isClickedOpen) {
      setOpen(true);
    }
  };

  const handleMouseLeave = () => {
    console.log("Mouse Leave", isClickedOpen);
    console.log("hamburgerStatus", hamburgerMenuOn);

    if (!isClickedOpen && !hamburgerMenuOn) {
      console.log("is clicked open", isClickedOpen);
      console.log("open is getting false here!!");
      setOpen(false);
    }
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{
        marginTop: "50px",
      }}
    >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
        <MenuItem onClick={handleLogout}>logout</MenuItem> */}

      <Box
        flexGrow={1}
        sx={{
          padding: "10px",
        }}
      >
        <p>Logged In as </p>
        <p>{email}</p>
        <Button variant="outlined" onClick={handleLogout}>
          Logout
        </Button>
      </Box>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        {/* <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          >
          </IconButton>
          <p>Profile</p> */}
        <Paper></Paper>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          onClick={handleLogout}
        ></IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ display: "flex", backgroundColor: "black" }}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          <Toolbar
            style={{
              background: "linear-gradient(to right, #4970C1, #4167A4)",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                handleDrawerOpen();
                setHamburgerMenuOn(!hamburgerMenuOn);
              }}
              edge="start"
              sx={{
                marginRight: 5,
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              TutorsHive Order Management
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
              {/* <Profile handleOpenProfile={handleProfileMenuOpen} /> */}
            </Box>
          </Toolbar>
        </AppBar>
        {renderMenu}
      </Box>

      <Drawer
        variant="permanent"
        open={open}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          width: open ? "200px" : 64,
          transition: "width 0.3s ease-in-out",
          overflow: "none",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          {/*  <IconButton onClick={() => { handleDrawerOpen(); console.log('IconButton Clicked'); }}>
              <ChevronLeftIcon />
            </IconButton> */}
        </Toolbar>
        <Grid
          minHeight="90%"
          minWidth="290px"
          overflow="auto"
          style={{
            background: "linear-gradient(to bottom, #4767A5, #4972C1)",
            overflowY: "none",
          }}
        >
          <Grid
            sx={{
              minHeight: "18%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {open && (
              <img
                src={logo2}
                style={{ width: "220px" }}
                alt="tutorsHive logo"
              />
            )}
          </Grid>
          <Grid sx={{ paddingTop: "0px", minHeight: "80%" }}>
            <SideItems isOpen={open} />
          </Grid>
        </Grid>
      </Drawer>
    </Box>
  );
};

export default NavBarMain;
