export const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "INR",
    label: "₹",
  },
  {
    value: "GBP",
    label: "£",
  },
  {
    value: "AUD",
    label: "A$",
  },
];
