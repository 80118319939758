import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import closeicon from "../icons/close-icon.png";
import { ThemeProvider } from "@emotion/react";
import {
  DialogContent,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { grey, red } from "@mui/material/colors";

const style = {
  position: "absolute",
  top: "345px",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  px: 3,
  innerHeight: 240,
  borderRadius: 2,
};

export default function CommonModal({
  modalIcon,
  type,
  title,
  content,
  isOpen,
  handleClose,
  subContent,
  handleConfirm,
}) {
  return (
    <>
      <Grid>
        <Modal
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              overflow: "auto",
              top: type === "paymentUpdate" ? "59%" : "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              flexDirection: "column",
              gap: type === "delete" ? "6px" : "0px",
              maxWidth: 600,
              maxHeight: 550,
              minHeight: 400,
              minWidth: 500,
              bgcolor: "background.paper",
              boxShadow: 24,
              paddingTop: 3,
              p: type === "paymentUpdate" ? 2 : 3,
              innerHeight: 240,
              borderRadius: 2,
            }}
          >
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                width: "100%",
                padding: "6px",
              }}
            >
              <Grid display="flex" alignItems="center" gap="15px">
                {modalIcon && (
                  <img
                    src={modalIcon}
                    style={{
                      width: "55px",
                      height: "55px",
                      objectFit: "contain",
                    }}
                    alt="salaryIcon"
                    className="image"
                  />
                )}
                {title && (
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    marginTop="16px"
                    fontWeight="bold"
                    component="h2"
                  >
                    {title}
                  </Typography>
                )}
              </Grid>
              <img
                onClick={handleClose}
                src={closeicon}
                style={{
                  width: "35px",
                  height: "35px",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                alt="close icon"
                className="image-close"
              />
            </Grid>

            {subContent && (
              <Typography
                id="modal-modal-description"
                sx={{ mt: "4px", color: "gray" }}
              >
                {subContent}
              </Typography>
            )}
            {content && (
              <DialogContent dividers fullWidth sx={{ padding: 3 }}>
                {content}
              </DialogContent>
            )}
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                marginTop: "8px",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                onClick={() => handleClose()}
                disableElevation
                sx={{
                  bgcolor: "white",
                  textTransform: "none",
                  width: "170px",
                  height: "44px",
                  color: grey[700],
                  border: "solid gray 0.5px",
                  borderRadius: "8px",
                  "&:hover": { bgcolor: "white" },
                }}
              >
                Cancel
              </Button>
              <Button
                disableElevation
                onClick={handleConfirm}
                variant="contained"
                sx={{
                  bgcolor: type === "delete" ? "#e53935" : "#4767A5",
                  textTransform: "none",
                  fontSize: "1rem",
                  width: "170px",
                  height: "44px",
                  borderRadius: "8px",
                  "&:hover": {
                    bgcolor: type === "delete" ? "#e53935" : "#4767A5",
                  },
                }}
              >
                {type == "edit" ? "Update" : "Confirm"}
              </Button>
            </Grid>
          </Box>
        </Modal>
      </Grid>
    </>
  );
}
