import { Box, Container, CssBaseline, Grid, InputAdornment, Paper, Typography } from '@mui/material'
import React, { useRef } from 'react'
import Logo from "./img/logo.jpg";
import { FormLabel, TextField, InputLabel, Select, MenuItem, Button, FormControlLabel } from '@mui/material'
import { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { FRONTEND_API } from "./urls";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import MessageComponent from './MessageComponent'
import EditIcon from '@mui/icons-material/Edit';

import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";

import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import PDFDemo from './styles/PDFDemo';
import LabelIcon from '@mui/icons-material/Label';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { ButtonContainer } from './styles/style';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DateCalendar } from '@mui/x-date-pickers';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#343F71',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#FBF1F7',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function generateInvoiceNumber() {
    // Generate a random 4-digit number
    const random4Digits = Math.floor(1000 + Math.random() * 9000);
    const invoiceNumber = `TH-${random4Digits}`;
    return invoiceNumber;
}

function generateOrderId() {
    // Generate a random 4-digit number
    const random4Digits = Math.floor(1000 + Math.random() * 9000);
    const orderID = `OD-${random4Digits}`;
    return orderID;
}
function EditInvoices() {

    const currencies = [
        {
            value: '$',
            label: 'US Dollar( USD, $)',
        },
        {
            value: '₹',
            label: 'Indian Rupee(INR, ₹)',
        },
        {
            value: '£',
            label: 'British Pound Sterling(GBP, £)',
        },
        {
            value: 'A$',
            label: 'Australian Dollar(AUD, $)',
        },
    ];

    let params = useParams();

    const [client, setclient] = useState([]);
    const [clientId, setClientId] = useState(0);
    const token = localStorage.getItem("token")
    const [currencyValue, setCurrencyValue] = React.useState(currencies.at(0).value)

    const [taxType, setTaxType] = React.useState('');

    const [selectedTax, setSelectedTax] = useState('');

    const [radioError, setRadioError] = useState(false);

    const [savedTax, setSavedTax] = React.useState('');
    const [saveSubTax, setSaveSubTax] = useState('');

    const [orders, setOrders] = useState([]);
    const [removedOrders, setRemovedOrders] = useState([]);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [discountDialog, setDiscountDialogOpen] = useState(false);
    const [paidAmountDialog, setPaidAmountDialog] = useState(false);
    const [dialogValidationOpen, setDialogValidationOpen] = useState(false);

    const [tableData, setTableData] = useState([
        { id: 0, order_id: '', item: '', taxRate: 0, quantity: 0, rate: 0, amount: 0, igst: 0, sgst: 0, cgst: 0, amount: 0, total: 0 },
    ]);
    const [selectedFile, setSelectedFile] = useState(null);
    /*  const today = dayjs();
     const nextDate = today.add(1, 'day');
    */
    const today = dayjs();
    const nextDate = today.add(1, 'day');

    const [dueDate, setDueDate] = useState(nextDate);
    const [invoiceDate, setInvoiceDate] = useState(today);

    const [invoiceNumber, setInvoiceNumber] = useState(null);

    const [discount, setDiscount] = useState(0);
    const [paidAmount, setPaidAmount] = useState(0);
    //const [dis, setTotal] = useState(0);

    const [alert, setAlert] = useState(false);
    const [status, setStatus] = useState('');
    const [alertContent, setAlertContent] = useState('');
    const [severity, setSeverity] = useState(null);


    const componentRef = useRef();

    const [openInvoiceDatePicker, setOpenInvoiceDatePicker] = useState(false);
    const [openDueDatePicker, setOpenDueDatePicker] = useState(false);

    const invoiceButtonRef = useRef(null);
    const dueButtonRef = useRef(null);

    const handleAddRow = () => {
        const newRow = {
            id: tableData.length + 1,
            name: `Row ${tableData.length + 1}`,
            order_id: generateOrderId(),
            description: `Description ${tableData.length + 1}`,
        };
        setTableData([...tableData, newRow]);
    };


    const handleCloseDialog = () => {
        setDialogValidationOpen(false);
    };

    const handleRemoveRow = (index) => {
        console.log("removed", index);
        const removeOrder = tableData.filter((row) => row.id == index);
        console.log("removed", removeOrder[0].order_id);
        
        const updatedRemovedOrders = removedOrders.concat(removeOrder[0].order_id);
        setRemovedOrders(updatedRemovedOrders);

        const updatedTableData = tableData.filter((row) => row.id !== index);
        setTableData(updatedTableData);
    }

    const handleTaxRadioChange = (event) => {
        //setSelectedRadio(event.target.value);
        console.log(event.target.value);
        setSelectedTax(event.target.value);
    };

    const handleCurrencyChange = (event) => {
        setCurrencyValue(event.target.value);
    };

    const handleCloseTax = () => {
        if (savedTax == null) {
            setDialogOpen(false);
            setTaxType('');
            setSelectedTax('');
        } else {
            setDialogOpen(false);
        }
    };

    const handleDiscount = () => {
        setDiscountDialogOpen(true)
    };

    const handlePaidDialog = () => {
        setPaidAmountDialog(true);
    }
    const handleClosePaidDialog = () => {
        console.log(discountDialog);
        setPaidAmountDialog(false);


    };

    const handleCloseDiscount = () => {
        console.log(discountDialog);
        setDiscountDialogOpen(false);
        setDiscount(0);

    };

    const handleSavePaidAmt = () => {
        if (paidAmountDialog) {
            setPaidAmountDialog(false);
        }
    };

    const handleSaveDiscount = () => {
        if (discountDialog) {
            setDiscountDialogOpen(false);
        }
    };

    const handleDiscountChange = (event) => {
        const { name, value } = event.target;
        setDiscount(value);
    };

    const handleTaxUpdate = () => {
        setDialogOpen(true);
    };

    const handleTaxTypeChange = (event) => {
        const { name, value } = event.target;
        if (savedTax != null)
            setTaxType(value);
        else
            setTaxType(savedTax);

    };

    const handleSaveTax = () => {
        if (taxType == 'gst') {
            if (selectedTax == '') {
                setRadioError(true);
            } else {
                setRadioError(false)
                setSavedTax(taxType)
                setSaveSubTax(selectedTax)

                setDialogOpen(false);
            }
        } else if (taxType == 'none') {
            setSavedTax('')
            setDialogOpen(false);
        } else {
            setSavedTax(taxType);
            setDialogOpen(false);
        }
    };


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    const handleFileChange = (event) => {
        const file = event.target.files[0];

        // Check if a file is selected
        if (file) {
            // Check file type
            const fileType = file.name.split('.').pop().toLowerCase();
            if (fileType === 'doc' || fileType === 'pdf' || fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png' || fileType === 'word') {
                // Check file size (2MB limit)
                if (file.size <= 2 * 1024 * 1024) {
                    setSelectedFile(file);
                } else {
                    alert('File size exceeds 2MB limit.');
                }
            } else {
                alert('Invalid file type. Please select a valid file.');
            }
        }
    };

    useEffect(() => {
        fetchInvocieById(params.id);
        //generateAndSetInvoiceNumber();
    }, []);

    const [clientInvoice, setClientInvoice] = useState(null);
    const [downloadPdf, setDownloadPdf] = useState(false);


    const downloadAttachemts = async (attachment) => {
        // Call your method here
        try {
            var formdata = new FormData();
            formdata.append("attachments", attachment)
            var requestOptions = {
                method: "POST",
                body: formdata,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            };
            const response = await fetch(FRONTEND_API + "download-attachemts", requestOptions);
            if (!response.ok) {
                throw new Error(`Failed to download PDF: ${response.statusText}`);
            }

            // Convert the response blob to a blob URL
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            // Create a link and trigger a click to download the PDF
            const link = document.createElement('a');
            link.href = url;
            link.download = `${attachment}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Optionally, revoke the blob URL to free up resources
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    const [discountType, setDiscountType] = React.useState('');

    const fetchInvocieById = (id) => {
        console.log("Invoce ID", id);
        fetch(FRONTEND_API + "/getInvoice/".concat(id), {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((res) => res.json())
            .then((data) => {
                // do something with data
                console.log("Invoice data", data);
                console.log("ClientId", data.data.client_id)
                setClientId(data.data.client_id)
                setPaidAmount(data.data.paid_amount);
                setOrders(data.data);
                setSavedTax(data.data.tax_type);
                setSaveSubTax(data.data.sub_tax);
                currencyExc(data.data.currency);
                setInvoiceNumber(data.data.invoice_number);
                setInvoiceDate(dayjs(data.data.invoice_date));
                setDueDate(dayjs(data.data.due_date));
                setDiscount(data.data.dis_percent);
                setDiscountType(data.data.discount_type);
                // navigate("/OTMform");
                updateTableDataWithReceivedData(tableData, data);
            })
            .catch((rejected) => {
                console.log(rejected);
            });
    };

    const currencyExc = (currency) => {
        if (currency === 'INR') {
            setCurrencyValue('₹');
        } else if (currency === 'GBP') {
            setCurrencyValue('£');
        } else if (currency === 'USD') {
            setCurrencyValue('$');
        } else {
            setCurrencyValue('A$');
        }
    }
    const updateTableDataWithReceivedData = (tableData, receivedData) => {
        // Assuming the receivedData array contains an object with the data you want to add
        console.log(receivedData);
        // Make a copy of the existing tableData array
        const updatedTableData = [...tableData];

        for (let i = 0; i < receivedData.invoices.length; i++) {
            const receivedItem = receivedData.invoices[i];

            updatedTableData[i] = {
                ...updatedTableData[i],
                id: i,
                order_id: receivedItem.order_id,
                amount: receivedItem.amount,
                item: receivedItem.item,
                total: receivedItem.item_total,
                quantity: receivedItem.quantity,
                rate: receivedItem.rate,
                taxRate: receivedItem.tax_rate,
                sgst: receivedItem.sgst,
                cgst: receivedItem.cgst,
                vat: receivedItem.vat,
            };
        }
        console.log(updatedTableData);
        // Set the updated tableData to the state
        setTableData(updatedTableData);
    };

    const handleInputChange = (event, field, id) => {
        const updatedTableData = tableData.map((row) => {
            if (row.id === id) {
                const newValue = event.target.value;
                let taxRate = parseFloat(row.taxRate) || 0;
                let quantity = parseFloat(row.quantity) || 0;
                let amount = parseFloat(row.amount) || 0;
                let rate = parseFloat(row.rate) || 0;

                if (field === 'quantity') {
                    quantity = parseFloat(newValue) || 0;
                } else if (field === 'rate') {
                    rate = parseFloat(newValue) || 0;
                } else if (field === 'taxRate') {
                    taxRate = parseFloat(newValue) || 0;
                }

                let igst = 0; // Initialize igst, cgst, sgst to 0
                let cgst = 0;
                let sgst = 0;
                let vat = 0;
                let total = 0;
                console.log(savedTax);
                amount = rate * quantity;
                if (savedTax === 'gst') {
                    if (saveSubTax === 'igst') {
                        igst = (taxRate / 100) * (amount);
                    } else if (saveSubTax === 'gst') {
                        cgst = (taxRate / 200) * (amount);
                        sgst = (taxRate / 200) * (amount);
                    }
                    total = amount + igst + cgst + sgst;
                } else if (savedTax === 'vat') {
                    console.log(taxRate / 100);
                    vat = (taxRate / 100) * (amount);
                    console.log(vat);
                    total = amount + vat;
                } else {
                    total = amount;
                }

                //igst = (taxRate / 100) * (amount);

                return { ...row, [field]: newValue, amount, igst, cgst, sgst, vat, total };
            }
            return row;
        });
        setTableData(updatedTableData);

    };

    const getTotal = () => {
        let total = 0;
        tableData.forEach((row) => {
            total += row.total;
        })
        if (discount != 0) {
            return total - getDiscount();
        } else if (paidAmount != 0 || paidAmount != null) {
            return total - paidAmount;
        }
        else {
            return total;
        }

    }

    //Logic to implement discount
    const getDiscount = () => {
        let total = 0;
        tableData.forEach((row) => {
            total += row.total;
        })
        console.log("discount check", discount);
        console.log("discount check", discountType);
        console.log("discount total", total);

        if (discountType == 10) {
            const discountAmount = (discount / 100) * total;
            console.log("discount amount", discountAmount);

            return discountAmount;
        } else {
            const discountAmount = discount * 1.0;
            console.log(discountAmount);
            return discountAmount;
        }

    }
    const getTotalAmount = () => {
        let total = 0;
        tableData.forEach((row) => {
            total += row.amount;
        })
        console.log(getDiscount())
        return total;
    }

    const getIgst = () => {
        let total = 0;
        if (savedTax == 'gst' && saveSubTax == 'igst') {
            tableData.forEach((row) => {
                total += row.igst;
            })
        }
        else if (savedTax == 'vat') {
            tableData.forEach((row) => {
                total += row.vat;
            })
        }
        return total;
    }

    const getSgst = () => {
        let total = 0;
        tableData.forEach((row) => {
            total += row.sgst
        })
        return total;
    }

    const getCgst = () => {
        let total = 0;
        tableData.forEach((row) => {
            total += row.cgst
        })
        return total;
    }

    function getFile(blob) {
        console.log(blob);
        const formData = new FormData();
        formData.append('invoicepdf', blob);
        console.log(formData);

        var requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: formData,
        };
        let status = 0;
        fetch(FRONTEND_API + 'uploadInvoice/'.concat(invoiceNumber), requestOptions)
            .then((response) => {
                if (response.status == 200) {
                    status = 200;
                    return response.json();
                } else {
                    status = response.status;
                    return response.json();
                }
            })
            .then((data) => {
                if (status == 200) {
                    setAlertContent(data.message);
                    setDialogValidationOpen(true);
                    setSeverity(true);
                    setDownloadPdf(false);
                } else {
                    setAlertContent(data.message);
                    setDialogValidationOpen(true);
                    setSeverity(false);
                    setDownloadPdf(false);
                }
                console.log(data);

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }


    const [discountFieldVisible, setDiscountFieldVisible] = useState(false);
    const [addDiscountButton, setaddDiscountButton] = useState(true);

    const showDiscountFields = () => {
        if (!discountFieldVisible) {
            setDiscountFieldVisible(true);
            setaddDiscountButton(false);
        }
    }


    const handleDisChangeValue = (event) => {
        setDiscountType(event.target.value);
    }

    const handlePaidAmountChange = (event) => {
        setPaidAmount(event.target.value);
    }


    const deleteAttachment = (name) => {
        const formData = new FormData();
        formData.append('attachment', name);
        console.log(formData);

        var requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: formData,
        };
        let status = 0;
        fetch(FRONTEND_API + 'delete_attachment', requestOptions)
            .then((response) => {
                if (response.status == 200) {
                    fetchInvocieById(params.id);
                    status = 200;
                    return response.json();
                } else {
                    status = response.status;
                    return response.json();
                }
            })
            .then((data) => {
                if (status == 200) {
                    setAlertContent(data.message);
                    setDialogValidationOpen(true);
                    severity(true);
                } else {
                    setAlertContent(data.message);
                    setDialogValidationOpen(true);
                    severity(false);
                }
                console.log(data);

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const handleInvoiceButtonClick = () => {
        if (openInvoiceDatePicker) {
            setOpenInvoiceDatePicker(false);
        } else {
            setOpenInvoiceDatePicker(true);
        }
    }

    const handleDueButtonClick = () => {
        if (openDueDatePicker) {
            setOpenDueDatePicker(false);
        } else {
            setOpenDueDatePicker(true);
        }
    }


    const handleInvoiceDate = (date) => {
        console.log("invoice date", date);
        setInvoiceDate(date);
        setOpenInvoiceDatePicker(false);
    }

    const handleDueDate = (date) => {
        setDueDate(date);
        setOpenDueDatePicker(false);
    }

    const updateInvoice = () => {
        //console.log(clientId);

        const data = tableData;

        if (invoiceDate == null) {
            setAlertContent("Please select Invoice Date");
            setDialogValidationOpen(true);
            severity(false);
            return
        } else if (dueDate == null) {
            setAlertContent("Please select Due Date");
            setDialogValidationOpen(true);
            severity(false);
            return
        } else if (data.length == 0) {
            console.log("Total", 'null');
            setDialogValidationOpen(true);
            setAlertContent("You have not added any item in the invoice.");
            setSeverity(false);
            return
        }

        var saveCurrency = '';

        if (currencyValue == '$') {
            saveCurrency = 'USD'
        } else if (currencyValue == '$AUD') {
            saveCurrency = 'AUD'
        } else if (currencyValue == '₹') {
            saveCurrency = 'INR'
        } else if (currencyValue == '£') {
            saveCurrency = 'GBP'
        }

        const formData = new FormData();
        formData.append('data', JSON.stringify({
            data: data,
            invoiceNumber: invoiceNumber,
            invoiceDate: invoiceDate.format("DD-MM-YYYY"),
            dueDate: dueDate.format("DD-MM-YYYY"),
            taxType: savedTax,
            discount: getDiscount(),
            diskPercent: discount,
            discountType: discountType,
            currency: saveCurrency,
            total: getTotal(),
            totalAmount: getTotalAmount(),
            clientId: clientId,
            subTax: saveSubTax,
            paidAmount: paidAmount,
            removedOrders: removedOrders
        }));

        formData.append('document', selectedFile);

        var requestOptions = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: formData,
        };
        console.log("order to remove", removedOrders);
        let status = 0;
        fetch(FRONTEND_API + 'updateInvoice', requestOptions)
            .then((response) => {

                if (response.status == 200) {
                    status = 200;
                    return response.json();
                } else {
                    status = response.status;
                    return response.json();
                }
            })
            .then((data) => {
                console.log("upload", data, status);
                if (status == 200) {
                    setTimeout(() => {
                        setDownloadPdf(true);
                    }, 2000);
                    setClientInvoice(data);
                } else {
                    setDialogValidationOpen(true);
                    setAlertContent(`Issue in Updating Invoice with error code ${status}`);
                    setSeverity(false);
                }
                // Log the response from the server
                // You can perform additional actions here if needed
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    };
    const epsilon = 0.0001;
    return (
        <Container sx={{ bgcolor: "#FBF1F7" }}>
            <Box sx={{ display: 'flex', }}>
                <CssBaseline />
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3}>
                        {/* Chart */}
                        <Grid item xs={12}>
                            <Paper sx={{ m: 5, p: 5, display: 'flex', flexDirection: 'column', border: '1px solid #C7A1BD' }} ref={componentRef}>

                                <Typography variant="h4" sx={{ display: 'flex', justifyContent: 'center', mb: '20px' }}>
                                    Invoice
                                </Typography>

                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <div>
                                                    <strong>Invoice No:</strong>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div>
                                                    <p>{invoiceNumber}</p>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div>
                                                    <strong>Date:</strong>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div>
                                                    <ButtonContainer
                                                        sx={{ m: 1 }}
                                                        role="button"
                                                        onClick={handleInvoiceButtonClick}
                                                        size='small'
                                                        ref={invoiceButtonRef}
                                                    >
                                                        <span style={{ marginLeft: '10px' }}>{invoiceDate === null ? '' : invoiceDate.format("DD-MM-YYYY")}</span>
                                                        <IconButton sx={{ display: 'flex', alignItems: 'end', alignContent: 'end' }}>
                                                            <CalendarTodayIcon />
                                                        </IconButton>
                                                    </ButtonContainer>

                                                    {openInvoiceDatePicker && (
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                zIndex: 99,
                                                                top: invoiceButtonRef.current.offsetTop + invoiceButtonRef.current.offsetHeight + 'px',
                                                                left: invoiceButtonRef.current.offsetLeft + 'px',
                                                                backgroundColor: '#fff',
                                                                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                                            }}>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DateCalendar
                                                                    value={invoiceDate}
                                                                    onChange={(newValue) => handleInvoiceDate(newValue)}
                                                                    renderInput={() => null} // Hide the input inside the calendar
                                                                    PopperProps={{
                                                                        style: { zIndex: 99, backgroundColor: '#fff' }, // Adjust z-index as needed
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div>
                                                    <strong>Add Due Date:</strong>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div>
                                                    <ButtonContainer
                                                        sx={{ m: 1 }}
                                                        role="button"
                                                        onClick={handleDueButtonClick}
                                                        size='small'
                                                        ref={dueButtonRef}
                                                    >
                                                        <span style={{ marginLeft: '10px' }}>{dueDate === null ? '' : dueDate.format("DD-MM-YYYY")}</span>
                                                        <IconButton sx={{ display: 'flex', alignItems: 'end', alignContent: 'end' }}>
                                                            <CalendarTodayIcon />
                                                        </IconButton>
                                                    </ButtonContainer>

                                                    {openDueDatePicker && (
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                zIndex: 99,
                                                                top: dueButtonRef.current.offsetTop + dueButtonRef.current.offsetHeight + 'px',
                                                                left: dueButtonRef.current.offsetLeft + 'px',
                                                                backgroundColor: '#fff',
                                                                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                                            }}>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DateCalendar
                                                                    value={dueDate}
                                                                    onChange={(newValue) => handleDueDate(newValue)}
                                                                    renderInput={() => null} // Hide the input inside the calendar
                                                                    PopperProps={{
                                                                        style: { zIndex: 99, backgroundColor: '#fff' }, // Adjust z-index as needed
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                    )}

                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={Logo} alt='BigCo Inc. logo' id='invoicelogo' />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} marginTop="10px">
                                    <Grid item xs={6}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: "#FBF1F7" }}>
                                            <Box sx={{ paddingBottom: 2 }}>
                                                <strong>Billed By-</strong>
                                                <p id='companylogo'>
                                                    <span id='tutorshivetext'> TutorsHive Pvt. Ltd. </span>
                                                    <br />
                                                    Regd. office: 88A, Nancy Residency, First Floor, <br />
                                                    Sindhu Nagar, Scheme No. 17, Murlipura, Jaipur, jaipur,
                                                    <br />
                                                    Email: info@webz.com.pl
                                                </p>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: "#FBF1F7", height: "160px" }}>
                                            <strong>Billed To-</strong>
                                            {orders != null && (
                                                <div>
                                                    <p>Name: {orders.client_name}</p>
                                                    <p>Email: {orders.client_email}</p>
                                                    <p>Contact: {orders.client_contact}</p>
                                                </div>
                                            )}
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                    <Button variant="outlined" sx={{ mt: 3, mb: 2, marginRight: 2 }}
                                        onClick={handleTaxUpdate}>
                                        {savedTax != '' ? 'Configure Tax' : 'Add Tax'}
                                    </Button>
                                    <InputLabel id="demo-simple-select-label" sx={{ marginRight: 2 }}>Currency</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={currencyValue}

                                        onChange={handleCurrencyChange}
                                        error={currencyValue === ''}
                                        helperText={currencyValue === '' && 'Select Currency'}
                                        fullWidth
                                        sx={{ width: '200px' }}
                                    >
                                        {currencies.map((data) => (

                                            <MenuItem value={data.value}>{data.label}</MenuItem>

                                        ))}
                                    </Select>
                                </div>

                                <Box>
                                    <TableContainer component={Paper} sx={{
                                        marginBottom: 6,
                                        marginRight: 2,
                                        mt: 3
                                    }}
                                        aria-label="customized table" >
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead fullWidth>
                                                <StyledTableRow>
                                                    <StyledTableCell>Item</StyledTableCell>
                                                    {savedTax && (
                                                        <StyledTableCell >{savedTax == 'gst' ? 'GST Rate' : 'VAT Rate'}</StyledTableCell>
                                                    )}
                                                    <StyledTableCell>Quantity</StyledTableCell>
                                                    <StyledTableCell >Rate</StyledTableCell>
                                                    {/* */}

                                                    {savedTax == 'gst' && saveSubTax == 'gst' && (

                                                        <StyledTableCell >Amount</StyledTableCell>


                                                    )}
                                                    {savedTax == 'gst' && saveSubTax == 'igst' && (

                                                        <StyledTableCell >Amount</StyledTableCell>


                                                    )}
                                                    {savedTax == 'gst' && saveSubTax == 'igst' && (
                                                        <StyledTableCell >IGST</StyledTableCell>
                                                    )}
                                                    {savedTax == 'gst' && saveSubTax == 'gst' && (

                                                        <StyledTableCell >CGST</StyledTableCell>


                                                    )}
                                                    {savedTax == 'gst' && saveSubTax == 'gst' && (

                                                        <StyledTableCell >SGST</StyledTableCell>

                                                    )}
                                                    {savedTax == 'vat' && (

                                                        <StyledTableCell >VAT</StyledTableCell>

                                                    )}

                                                    <StyledTableCell >Total</StyledTableCell>
                                                    <StyledTableCell ></StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>


                                            {tableData.length > 0 && tableData.map((data, index) => (
                                                <TableBody key={data.id}>
                                                    <StyledTableCell>
                                                        <div>{data.order_id}</div>
                                                        <TextField
                                                            variant="standard"
                                                            value={data.item}
                                                            onChange={(event) => handleInputChange(event, 'item', data.id)}
                                                        />
                                                    </StyledTableCell>
                                                    {savedTax && (
                                                        <StyledTableCell>
                                                            <div>
                                                                <TextField
                                                                    variant="standard"
                                                                    value={data.taxRate}
                                                                    onChange={(event) => handleInputChange(event, 'taxRate', data.id)}
                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                                    }}
                                                                />

                                                            </div>
                                                        </StyledTableCell>
                                                    )}
                                                    <StyledTableCell>
                                                        <TextField id="standard-basic"

                                                            variant="standard"
                                                            value={data.quantity}
                                                            onChange={(event) => handleInputChange(event, 'quantity', data.id)}
                                                        />
                                                    </StyledTableCell>

                                                    <StyledTableCell>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>
                                                            <TextField
                                                                id="standard-basic"

                                                                variant="standard"
                                                                value={data.rate}
                                                                onChange={(event) => handleInputChange(event, 'rate', data.id)}
                                                            />
                                                        </div>
                                                    </StyledTableCell>

                                                    {savedTax == 'gst' && saveSubTax == 'igst' && (
                                                        <StyledTableCell>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>

                                                                <TextField
                                                                    id="standard-basic"
                                                                    disabled
                                                                    variant="standard"
                                                                    value={data.amount}
                                                                    onChange={(event) => handleInputChange(event, 'amount', data.id)}
                                                                />

                                                            </div>
                                                        </StyledTableCell>
                                                    )}

                                                    {savedTax == 'gst' && saveSubTax == 'igst' && (
                                                        <div>


                                                            <StyledTableCell >
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>

                                                                    <TextField
                                                                        id="standard-basic"
                                                                        disabled
                                                                        variant="standard"
                                                                        value={data.igst != null ? data.igst.toFixed(2) : 0}
                                                                        onChange={(event) => handleInputChange(event, 'igst', data.id)}

                                                                    />

                                                                </div>
                                                            </StyledTableCell>
                                                        </div>
                                                    )}
                                                    {savedTax == 'gst' && saveSubTax == 'gst' && (
                                                        <StyledTableCell>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>

                                                                <TextField
                                                                    id="standard-basic"
                                                                    disabled
                                                                    variant="standard"
                                                                    value={data.amount}
                                                                    onChange={(event) => handleInputChange(event, 'amount', data.id)}
                                                                />

                                                            </div>
                                                        </StyledTableCell>
                                                    )}
                                                    {savedTax == 'gst' && saveSubTax == 'gst' && (
                                                        <div>


                                                            <StyledTableCell >
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>
                                                                    <TextField
                                                                        disabled
                                                                        id="standard-basic"

                                                                        variant="standard"
                                                                        value={data.cgst != null ? data.cgst.toFixed(2) : 0}
                                                                        onChange={(event) => handleInputChange(event, 'cgst', data.id)}

                                                                    />
                                                                </div>
                                                            </StyledTableCell>
                                                        </div>

                                                    )}
                                                    {savedTax == 'gst' && saveSubTax == 'gst' && (
                                                        <StyledTableCell >
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>

                                                                <TextField
                                                                    disabled
                                                                    id="standard-basic"
                                                                    variant="standard"
                                                                    value={data.sgst != null ? data.sgst.toFixed(2) : 0}
                                                                    onChange={(event) => handleInputChange(event, 'sgst', data.id)}

                                                                />
                                                            </div>
                                                        </StyledTableCell>
                                                    )}

                                                    {savedTax == 'vat' && (

                                                        <StyledTableCell >
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>

                                                                <TextField
                                                                    disabled
                                                                    id="standard-basic"
                                                                    variant="standard"
                                                                    value={data.vat != null ? data.vat.toFixed(2) : 0}
                                                                    onChange={(event) => handleInputChange(event, 'vat', data.id)}

                                                                />
                                                            </div>
                                                        </StyledTableCell>

                                                    )}

                                                    <StyledTableCell>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>

                                                            {data.total != null ? data.total.toFixed(2) : 0}
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell> <CloseIcon onClick={() => handleRemoveRow(data.id)} /></StyledTableCell>
                                                </TableBody>
                                            ))}

                                        </Table>
                                    </TableContainer>

                                    {tableData.length >= 1 && (
                                        <Grid alignItems='end' display='flex' justifyContent='flex-end' container spacing={2}>
                                            <Grid item xs={9}>
                                                <Typography align="right" variant="subtitle1">
                                                    Amount:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography align="right" variant="subtitle1">
                                                    <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>
                                                    {isNaN(getTotalAmount()) ? 0 : getTotalAmount().toFixed(2)}
                                                </Typography>
                                            </Grid>

                                            {savedTax === 'gst' && saveSubTax === 'gst' && (
                                                <>
                                                    <Grid item xs={9}>
                                                        <Typography align="right" variant="subtitle1">
                                                            SGST:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography align="right" variant="subtitle1">
                                                            <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>
                                                            {isNaN(getSgst()) ? 0 : getSgst().toFixed(2)}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={9}>
                                                        <Typography align="right" variant="subtitle1">
                                                            CGST:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography align="right" variant="subtitle1">
                                                            <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>
                                                            {isNaN(getCgst()) ? 0 : getCgst().toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            )}

                                            {savedTax === 'gst' && saveSubTax === 'igst' && (
                                                <>
                                                    <Grid item xs={9}>
                                                        <Typography align="right" variant="subtitle1">
                                                            IGST:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography align="right" variant="subtitle1">
                                                            <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>
                                                            {isNaN(getIgst()) ? 0 : getIgst().toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            )}

                                            {savedTax === 'vat' && (
                                                <>
                                                    <Grid item xs={9}>
                                                        <Typography align="right" variant="subtitle1">
                                                            VAT:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography align="right" variant="subtitle1">
                                                            <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>
                                                            (+){isNaN(getIgst()) ? 0 : getIgst().toFixed(2)}
                                                        </Typography>
                                                    </Grid>

                                                </>
                                            )}



                                            {paidAmount != 0 && paidAmount != null && (
                                                <>
                                                    <Grid item xs={9}>
                                                        <div>
                                                            <Typography align="right" variant="subtitle1">
                                                                Paid Amount:
                                                                <EditIcon onClick={handlePaidDialog} sx={{ marginLeft: '5px' }} fontSize='small' />
                                                            </Typography>

                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography align="right" variant="subtitle1">
                                                            <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>
                                                            (-) {paidAmount}
                                                            {/* <TextField
                                sx={{width: '30px'}}
                                variant="standard"
                                value={paidAmount}
                                onChange={(event) => handlePaidAmountChange(event)}
                              /> */}
                                                        </Typography>
                                                    </Grid>

                                                </>

                                            )}

                                            {Math.abs(discount) > epsilon ? (
                                                <>
                                                    <Grid item xs={9}>

                                                        <Typography align="right" variant="subtitle1">
                                                            Discount({discount}{discountType == '10' ? '%' : currencyValue}):
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography align="right" variant="subtitle1">
                                                            <span style={{ marginRight: '5px', marginTop: '0px' }}>{currencyValue}</span>
                                                            (-){isNaN(getDiscount()) ? 0 : getDiscount().toFixed(2)}
                                                        </Typography>
                                                    </Grid>

                                                </>

                                            ) : (<></>)}

                                            <Grid item xs={9}>

                                                <Typography align="right" variant="h5" fontWeight="semi-bold">
                                                    Total:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography align="right" variant="h5" fontWeight="bold">
                                                    <span style={{ marginRight: '5px', marginTop: '0px', fontWeight: "bold" }}>{currencyValue}</span>
                                                    {isNaN(getTotal()) ? 0 : getTotal().toFixed(2)}
                                                </Typography>
                                            </Grid>


                                        </Grid>


                                    )}
                                    <div style={{ marginTop: '10px', display: "flex", alignContent: "end", alignItems: "end" }}>
                                        {addDiscountButton && (

                                            <div style={{ display: "flex", alignContent: "end", alignItems: "end" }}>

                                                <span><LabelIcon style={{ transform: 'rotate(240deg)' }} /></span>

                                                <div role='button' onClick={showDiscountFields}>
                                                    <span style={{ marginLeft: '5px' }}>Add Discounts/Additional Charges</span>
                                                </div>

                                            </div>
                                        )}


                                        {discountFieldVisible && (
                                            <div>
                                                <div style={{ marginLeft: '15px', display: "flex", alignContent: "flex-end", alignItems: "end" }} >

                                                    <span > <AddBoxIcon /> </span>
                                                    <div role='button' onClick={handleDiscount}>
                                                        <span >Give Discount on Total Amount</span>
                                                    </div>

                                                </div>
                                                <div style={{ marginLeft: '15px', display: "flex", alignContent: "flex-end", alignItems: "end" }} >

                                                    <span > <AddBoxIcon /> </span>
                                                    <div role='button' onClick={handlePaidDialog}>
                                                        <span >Amount Paid</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <Button variant='outlined' sx={{ mt: 3 }} onClick={handleAddRow}>
                                        Add More Items
                                    </Button>

                                </Box>

                                <div style={{ marginTop: '15px' }}>
                                    <input type="file" accept=".doc, .pdf, .jpeg, .jpg, .png, .word" onChange={handleFileChange} />

                                    {orders.attachment != null && orders.attachment.map((attach, index) => (
                                        <div>
                                            {index + 1}.
                                            <Link
                                                onClick={() => downloadAttachemts(attach.name)}>
                                                {attach.name}
                                            </Link>
                                            <CloseIcon onClick={() => deleteAttachment(attach.name)} />
                                        </div>
                                    ))}
                                </div>
                                {tableData.length >= 1 && (
                                    <Button variant="outlined" type='submit' sx={{ mt: 3, width: '200px' }} onClick={handlePrint}>
                                        Print & Save as PDF
                                    </Button>
                                )}
                                {downloadPdf && (
                                    <PDFDownloadLink document={<PDFDemo invoice={clientInvoice} />} fileName="example.pdf">
                                        {({ blob, url, loading, error }) => {
                                            if (!loading && blob) {
                                                // Use the blob as needed in your application logic
                                                console.log('PDF Blob:', blob);
                                                getFile(blob);
                                                // You can send the blob to the backend here or perform any other action
                                                // const blobUrl = URL.createObjectURL(blob);

                                                // // Create a link element
                                                // const downloadLink = document.createElement('a');
                                                // downloadLink.href = blobUrl;
                                                // downloadLink.download = 'example.pdf';

                                                // // Append the link to the document
                                                // document.body.appendChild(downloadLink);

                                                // // Trigger a click event to start the download
                                                // downloadLink.click();

                                                // // Remove the link from the document
                                                // document.body.removeChild(downloadLink);
                                            }

                                            return null; // This will prevent rendering any visible content
                                        }}
                                    </PDFDownloadLink>
                                )}
                                <Button variant="outlined" type='submit' sx={{ mt: 3, width: '200px' }} onClick={updateInvoice}>
                                    Update Invoice
                                </Button>

                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <BootstrapDialog fullWidth open={paidAmountDialog}>
                <DialogTitle>
                    <strong>Edit Paid Amount</strong>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClosePaidDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent dividers fullWidth sx={{ padding: 3 }}>
                    <InputLabel id="demo-simple-select-label" sx={{ mb: 1 }}>Enter Paid amount</InputLabel>
                    <div>
                        <TextField
                            id="standard-basic"
                            variant="standard"
                            value={paidAmount}
                            onChange={(event) => handlePaidAmountChange(event)}
                        /*  InputProps={{
                           endAdornment: <InputAdornment position="end">%</InputAdornment>,
                         }} */

                        />

                    </div>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClosePaidDialog} color="primary">
                        Close
                    </Button>
                    <Button onClick={handleSavePaidAmt} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </BootstrapDialog>



            <BootstrapDialog fullWidth open={discountDialog}>
                <DialogTitle>
                    <strong>Add Discount</strong>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDiscount}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent dividers fullWidth sx={{ padding: 3 }}>
                    <InputLabel id="demo-simple-select-label" sx={{ mb: 1 }}>Enter Discount</InputLabel>
                    <div>
                        <TextField
                            id="standard-basic"
                            variant="standard"
                            value={discount}
                            onChange={(event) => handleDiscountChange(event)}
                        /*  InputProps={{
                           endAdornment: <InputAdornment position="end">%</InputAdornment>,
                         }} */

                        />
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={discountType}
                            onChange={handleDisChangeValue}
                        >
                            <MenuItem value={10}>%</MenuItem>
                            <MenuItem value={20}>{currencyValue}</MenuItem>
                        </Select>

                    </div>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseDiscount} color="primary">
                        Close
                    </Button>
                    <Button onClick={handleSaveDiscount} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </BootstrapDialog>

            <BootstrapDialog fullWidth open={dialogOpen} sx={{

            }}>
                <DialogTitle>
                    <strong>Configure Tax</strong>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseTax}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers fullWidth sx={{ padding: 3 }}>
                    <InputLabel id="demo-simple-select-label" sx={{ mb: 1 }}>Select Tax Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={taxType}
                        placeholder='Tax'
                        onChange={handleTaxTypeChange}
                        fullWidth
                        sx={{ mb: 1 }}
                    >
                        <MenuItem value={'none'}>None</MenuItem>
                        <MenuItem value={'gst'}>GST (India)</MenuItem>
                        <MenuItem value={'vat'}>VAT</MenuItem>

                    </Select>

                    {taxType == 'gst' && (
                        <Box sx={{ marginTop: 3 }}>
                            <strong>GST Type</strong>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={selectedTax}
                                onChange={handleTaxRadioChange}
                            >

                                <FormControlLabel value="igst" control={<Radio />} label="IGST" />

                                <FormControlLabel value="gst" control={<Radio />} label="CGST & SGST" />
                            </RadioGroup>
                            {radioError && <p style={{ color: 'red' }}>Please select a tax option.</p>}
                        </Box>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseTax} color="primary">
                        Close
                    </Button>
                    <Button onClick={handleSaveTax} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </BootstrapDialog>

            {dialogValidationOpen && (
                <MessageComponent message={alertContent} severity={severity} close={handleCloseDialog} />
            )}

            {/*   <Dialog open={dialogValidationOpen} onClose={handleCloseDialog}>
        <DialogTitle>Form Validation Failed</DialogTitle>
        <DialogContent>
          <DialogContentText>Please fill in all required fields correctly.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog> */}
        </Container>
    )
}

export default EditInvoices