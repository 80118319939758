import React from "react";
import { BootstrapDialog } from "./styles/DialogStyle";
import Register from "./Register";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const AddUserDialog = ({ open, handleClose }) => {
  return (
    <BootstrapDialog
      open={open}
      sx={{ paddingX: 9, paddingY: 4 }}
      onClose={handleClose}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Register />
    </BootstrapDialog>
  );
};

export default AddUserDialog;
