import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from '@mui/material';
import { currencies } from './constants/constants';
import { BootstrapDialog } from './styles/DialogStyle';


const OrderDetails = ({ open, orders, onClose }) => {
    const handleDate = (expertDate) => {

        var formattedDate = null
        if (expertDate != null) {
            const dateObject = new Date(expertDate);
            formattedDate = dateObject.toDateString();
        }
        return formattedDate;
    }

    const getCurrencyLabel = (currencyValue) => {
        const currency = currencies.find(curr => curr.value === currencyValue);
        return currency ? currency.label : null;
    };

    console.log("orders", orders)
    return (
        <BootstrapDialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Order Details
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Grid sx={{ marginTop: 2 }} container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                    <Grid item xs={3}>
                        <p>Order Id:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p>{orders.id}</p>
                    </Grid>
                    <Grid item xs={3}>
                        <p>Client:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p>{orders.client_id}</p>
                    </Grid>
                    {/* <Grid item xs={3}>
                        <p>Description:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p>{orders.description}</p>
                    </Grid> */}
                    <Grid item xs={3}>
                        <p>Comment for Expert:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p>{orders.comments}</p>
                    </Grid>

                    <Grid item xs={3}>
                        <p>Order Added on:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p>{handleDate(orders.order_end_date)}</p>
                    </Grid>

                    {/* <Grid item xs={3}>
                        <p>Budget:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p>{getCurrencyLabel(orders.currency)}{orders.budget}</p>
                    </Grid> */}
                    <Grid item xs={3}>
                        <p>Word Count:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p>{orders.word_count}</p>
                    </Grid>
                </Grid>
            </DialogContent>



        </BootstrapDialog>
    )
}

export default OrderDetails;