export const handleDates = (expertDates) => {
  if (!expertDates || expertDates.length === 0) {
    return null;
  }

  // Ensure expertDates is an array
  if (!Array.isArray(expertDates)) {
    expertDates = [expertDates];
  }

  const formattedDates = expertDates.map((dateString) => {
    const dateObject = new Date(dateString);
    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-based month index
    const year = dateObject.getFullYear();

    // Format date as "dd/mm/yyyy"
    return `${day}/${month}/${year}`;
  });

  return formattedDates.join(", ");
};
